import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: ${({ theme }) => theme.spacing[12]};

  width: 100%;

  label {
    ${({ theme }) => theme.typography['title-tiny']};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  gap: ${({ theme }) => theme.spacing[4]};
`;

export const InputContainer = styled.div<{ disabled?: boolean; readOnly?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  outline: none;

  gap: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => theme.spacing[16]} ${({ theme }) => theme.spacing[12]};

  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.typography['body-medium']};

  ${({ disabled, readOnly, theme }) =>
    (disabled || readOnly) &&
    css`
      background-color: ${theme.colors.gray6};
      color: ${theme.colors.gray3};
      border: none;
    `}
`;

export const Input = styled.input`
  flex: 1;

  background-color: transparent;

  ${({ theme }) => theme.typography['body-medium']};
  color: ${({ theme }) => theme.colors.black};

  border: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray3};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray3};
  }
`;

export const HelperText = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography['caption-regular']};
  color: ${({ theme }) => theme.colors.black};
`;
