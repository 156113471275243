export const Colors = {
  // Primary
  'blue-100': '#1155CC',
  'blue-80': '#3D73D2',
  'blue-60': '#6BA9F9',
  'blue-40': '#93AEDE',
  'blue-20': '#BDCBE3',

  // Secondary
  'sky-100': '#6BA9F9',
  'sky-80': '#84B6F6',
  'sky-60': '#9DC2F2',
  'sky-40': '#B7CFF0',
  'sky-20': '#CFDCEC',

  // Tertiary
  'navy-100': '#003EAB',
  'navy-80': '#2F61B8',
  'navy-60': '#5D82C4',
  'navy-40': '#8CA5D0',
  'navy-20': '#BAC6DC',

  // Point
  'lime': '#BFFF00',

  // Grayscale
  'gray1': '#333333',
  'gray2': '#4F4F4F',
  'gray3': '#828282',
  'gray4': '#BDBDBD',
  'gray5': '#E0E0E0',
  'gray6': '#F9F9F9',

  // System
  'green': '#14AE5C',
  'yellow': '#EBC812',
  'red': '#F24822',

  'green-100': '#14AE5C',
  'green-80': '#33C774',
  'green-60': '#52E090',
  'green-40': '#70F9AD',
  'green-20': '#8FFFD1',

  'red-100': '#F24822',
  'red-80': '#F46640',
  'red-60': '#F6845E',
  'red-40': '#F8A27C',
  'red-20': '#FABF9A',

  // Monochrome
  'white': '#FFFFFF',
  'black': '#1B1B1B',
} as const;

export const makeOpacityColor = (color: keyof typeof Colors, opacity: number) => {
  return Colors[color] + opacity.toString(16);
};
