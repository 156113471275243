import styled from '@emotion/styled';

export const Container = styled.div<{ isDarkMode?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100vw;
  height: 100vh;

  background-color: ${({ theme, isDarkMode }) =>
    isDarkMode ? theme.colors.black : theme.colors.gray6};
  color: ${({ theme, isDarkMode }) => (isDarkMode ? theme.colors.white : theme.colors['blue-100'])};
`;

export const TimerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.typography['title-large']}

  font-size: 14vw;

  width: 100%;
  flex: 1;
`;

export const TimerNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
`;

export const TimerSeparator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;
