import db from '../../firebase';
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';

export const deleteWordsByCategoryId = async (categoryId: string) => {
  const querySnapshot = await getDocs(
    query(collection(db, 'Words'), where('scid', '==', categoryId)),
  );

  querySnapshot.forEach(async (d) => {
    await deleteDoc(doc(db, 'Words', d.id));
  });
};
