import { DashboardLayout } from 'components/templates';
import * as S from './WordCategoryPage.styled';
import { useEffect, useState } from 'react';
import useGetWordCategory from 'api/schedule/useGetWordCategory';
import useLoadingIndicator from 'hooks/useLoadingIndicator';
import { GrowingSchedule } from 'types';
import { CategoryTable, EmptyCategory, FilterCategorySideBar } from '..';

const branchOptions = [
  { label: '전체', value: 'common' },
  { label: '본관', value: 'mandeok-1' },
  { label: '신관', value: 'mandeok-2' },
];

export const WordCategoryPage = () => {
  const [branch, setBranch] = useState('common');
  const [categoryName, setCategoryName] = useState('');
  const {
    data: wordCategory,
    isLoading: isWordCategoryLoading,
    refetch: refetchWordCategory,
  } = useGetWordCategory();

  const [isShowResetButton, setIsShowResetButton] = useState(false);
  const [categories, setCategories] = useState<GrowingSchedule[]>([]);

  const isButtonDisabled = isWordCategoryLoading;

  const handleReset = () => {
    setBranch('common');
    setCategoryName('');
    setCategories(wordCategory);
    setIsShowResetButton(false);
  };

  const handleBranchChange = (value: string) => {
    setBranch(value);
  };

  const handleCategoryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryName(e.target.value);
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (categoryName === '' && branch === 'common') {
      setCategories(wordCategory);
      setIsShowResetButton(false);
    } else {
      const filteredCategories = wordCategory.filter((item) => {
        const isBranchMatch = branch === 'common' || item.branch === branch;
        const isNameMatch = categoryName === '' || item.name.includes(categoryName);
        return isBranchMatch && isNameMatch;
      });

      setCategories(filteredCategories);
      setIsShowResetButton(true);
    }
  };

  useLoadingIndicator({
    id: 'word-category-search',
    isShow: isWordCategoryLoading,
  });

  useEffect(() => {
    setCategories(wordCategory);
  }, [wordCategory]);

  return (
    <DashboardLayout>
      <S.Container>
        <FilterCategorySideBar
          branch={branch}
          branchOptions={branchOptions}
          categoryName={categoryName}
          onBranchChange={handleBranchChange}
          onCategoryNameChange={handleCategoryNameChange}
          onSearch={handleSearch}
          onReset={handleReset}
          refetchWhenCreateCategorySuccess={refetchWordCategory}
          isCreateButtonDisabled={isButtonDisabled}
          isShowResetButton={isShowResetButton}
        />
        <S.ContentContainer>
          <S.DataTable>
            <S.Header>
              <S.Cell flex={1} />
              <S.Cell flex={3}>지점</S.Cell>
              <S.Cell flex={3}>카테고리</S.Cell>
              <S.Cell flex={3}>세트 시작 번호</S.Cell>
              <S.Cell flex={3}>단어 개수</S.Cell>
              <S.Cell flex={3} />
            </S.Header>
            {categories.length === 0 ? (
              <EmptyCategory />
            ) : (
              <CategoryTable
                categories={categories}
                refetchWordCategory={refetchWordCategory}
                isDisabled={isButtonDisabled}
              />
            )}
          </S.DataTable>
        </S.ContentContainer>
      </S.Container>
    </DashboardLayout>
  );
};
