import { useEffect, useState } from 'react';

import * as S from './StopwatchPage.styled';
import { TeachingCenterButton } from 'components/atoms';
import { useNavigate } from 'react-router-dom';

export const StopwatchPage = () => {
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const navigate = useNavigate();

  const handleDarkModeToggle = () => {
    setIsDarkMode((prev) => !prev);
  };

  const handleBackButtonClick = () => {
    setTimerOn(false);
    navigate(-1);
  };

  const handleStart = () => {
    setTimerOn(true);
  };

  const handleStop = () => {
    setTimerOn(false);
  };

  const handleReset = () => {
    setTime(0);
  };

  const handleResume = () => {
    setTimerOn(true);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!timerOn) {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timerOn]);

  return (
    <S.Container isDarkMode={isDarkMode}>
      <S.TimerHeader>
        <TeachingCenterButton size="small" flexibility="fixed" onClick={handleDarkModeToggle}>
          {isDarkMode ? 'Dark' : 'Light'} Mode
        </TeachingCenterButton>
        <TeachingCenterButton size="small" flexibility="fixed" onClick={handleBackButtonClick}>
          Back
        </TeachingCenterButton>
      </S.TimerHeader>
      <S.TimerContainer>
        <S.TimerNumber>{('0' + Math.floor((time / 60000) % 60)).slice(-2)}</S.TimerNumber>
        <S.TimerSeparator>:</S.TimerSeparator>
        <S.TimerNumber>{('0' + Math.floor((time / 1000) % 60)).slice(-2)}</S.TimerNumber>
        <S.TimerSeparator>:</S.TimerSeparator>
        <S.TimerNumber>{('0' + ((time / 10) % 100)).slice(-2)}</S.TimerNumber>
      </S.TimerContainer>
      <S.TimerFooter>
        {!timerOn && time === 0 && (
          <TeachingCenterButton onClick={handleStart}>Start</TeachingCenterButton>
        )}
        {timerOn && <TeachingCenterButton onClick={handleStop}>Stop</TeachingCenterButton>}
        {!timerOn && time > 0 && (
          <TeachingCenterButton onClick={handleReset}>Reset</TeachingCenterButton>
        )}
        {!timerOn && time > 0 && (
          <TeachingCenterButton onClick={handleResume}>Resume</TeachingCenterButton>
        )}
      </S.TimerFooter>
    </S.Container>
  );
};
