import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { makeOpacityColor } from 'styles/theme/colors';

interface BorderColorConditions {
  isActive: boolean;
  isError: boolean;
  disabled: boolean;
}

const borderColor = ({ isActive, isError, theme }: BorderColorConditions & { theme: Theme }) => {
  if (isActive) {
    return theme.colors['blue-100'];
  }
  if (isError) {
    return theme.colors.red;
  }
  return theme.colors.gray5;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
`;

export const InputContainer = styled.div<BorderColorConditions>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 54px;

  padding: 0 15px;

  gap: 16px;

  border: 1px solid ${borderColor};
  border-radius: 8px;

  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray6 : theme.colors.white};

  transition: all 0.2s ease-in-out;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  flex: 1;

  position: relative;
`;

export const Label = styled.label<{ isFocused?: boolean }>`
  color: ${makeOpacityColor('gray1', 30)};
  top: 16px;

  ${({ theme }) => theme.oldTypography.Body1}

  position: absolute;

  ${({ isFocused }) =>
    isFocused &&
    `
    top: 8px;
    font-size: 10px;
    line-height: 12px;
    `};

  transition: all 0.3s ease-in-out;
`;

export const Input = styled.input<{ disabled: boolean }>`
  width: 100%;

  position: absolute;

  bottom: 8px;

  border: none;
  outline: none;

  padding: 0;

  color: ${({ disabled, theme }) =>
    disabled ? makeOpacityColor('gray1', 30) : theme.colors.gray1};

  ${({ theme }) => theme.oldTypography.Body1};

  &:focus {
    border: none;
    outline: none;
  }

  &:invalid {
    box-shadow: none;
  }
`;

export const ErrorIconContainer = styled.div<{ isShowError: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-in-out;

  opacity: ${({ isShowError }) => (isShowError ? 1 : 0)};
`;

export const HelperText = styled.span<{ isError?: boolean }>`
  color: ${({ isError: isShowError, theme }) =>
    isShowError ? theme.colors.red : theme.colors.black};
  ${({ theme }) => theme.oldTypography.Caption1};
  text-align: left;

  position: absolute;

  left: 16px;
  bottom: -20px;
`;
