import { Icon } from 'components/atoms';
import * as S from './TeachingCenterCheckbox.styled';
import { TeachingCenterCheckboxProps } from './TeachingCenterCheckbox.types';

export const TeachingCenterCheckbox = ({
  id,
  label,
  checked,
  disabled,
  onChange,
}: TeachingCenterCheckboxProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    onChange?.(e);
  };

  return (
    <S.Label htmlFor={id} disabled={disabled}>
      <input type="checkbox" id={id} checked={checked} onChange={handleChange} />
      <S.Checkbox checked={checked} disabled={disabled}>
        {checked && <Icon icon="CheckIcon" />}
      </S.Checkbox>
      {label && <S.CheckboxText disabled={disabled}>{label}</S.CheckboxText>}
    </S.Label>
  );
};
