import { TeachingCenterButtonProps } from 'components/atoms';
import * as S from './Sidebar.styled';
import { TeachingCenterCheckboxProps } from '../forms';
import { Content } from './content';
import { Form } from './form';
import { CheckboxContainer } from './checkbox-container';
import { ButtonContainer } from './button-container';

interface SideBarProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  checkBoxes?: TeachingCenterCheckboxProps[];
  buttons?: TeachingCenterButtonProps[];
}

export const SideBar = ({ title, description, children }: SideBarProps) => {
  return (
    <S.Container>
      <S.TitleContainer>
        <S.Title>{title}</S.Title>
        {description && <S.Description>{description}</S.Description>}
      </S.TitleContainer>
      {children}
    </S.Container>
  );
};

SideBar.Content = Content;
SideBar.Form = Form;
SideBar.CheckboxContainer = CheckboxContainer;
SideBar.ButtonContainer = ButtonContainer;
