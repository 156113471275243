import { Dialog, DialogService } from 'components/molecules';
import * as S from './DeleteCategoryDialog.styled';
import { showToast } from 'components/blocks';

interface DeleteCategoryDialogProps {
  onDelete: () => Promise<void>;
  isLoading: boolean;
}

export const DeleteCategoryDialog = ({ onDelete, isLoading }: DeleteCategoryDialogProps) => {
  const handleClose = () => {
    DialogService.close();
  };

  const handleDelete = async () => {
    try {
      await onDelete();
      handleClose();
      showToast({
        type: 'success',
        message: '카테고리가 삭제되었습니다.',
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: '오류가 발생했습니다. 다시 시도해주세요.',
      });
    }
  };

  return (
    <Dialog
      isCloseableViaBackdrop={false}
      title="카테고리를 삭제하시겠습니까?"
      description={
        <S.Description>
          {`확인을 누르면 카테고리가 삭제됩니다.
카테고리 내 세트 및 단어도 모두 삭제되며\n`}
          <S.RedText>확인 후 취소가 불가능</S.RedText>
          {`합니다.`}
        </S.Description>
      }
      onClose={handleClose}
      buttons={[
        { children: '확인', variant: 'outlined', onClick: handleDelete, disabled: isLoading },
        { children: '취소', variant: 'filled', onClick: handleClose, disabled: isLoading },
      ]}
      isShowHeader={false}
    />
  );
};
