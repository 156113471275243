import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100vw;

  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  flex-direction: column;

  text-align: left;
  padding: 40px 24px;
`;

export const HeaderText = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 50px;
  font-weight: bold;
  line-height: 60px;
`;
