import * as S from './LearningCenterNavigation.styled';
import logo from '../../../../assets/images/growing_symbol_sm.png';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from 'components/atoms';

const HOME_PATH = '/learningcenter/home';

interface StudentNavigatorProps {
  handleLogout: () => Promise<void>;
}

export default function LearningCenterNavigation({ handleLogout }: StudentNavigatorProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogoSection = () => {
    if (pathname === HOME_PATH) {
      return;
    }
    navigate(HOME_PATH);
  };

  const handleMenuButton = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleListItem = (link: string) => {
    navigate(link);

    setIsMenuOpen((prev) => !prev);
  };

  const menuItemList = [
    { label: '단어', link: '/learningcenter/voca' },
    { label: '본문', link: '/learningcenter/reading' },
    { label: '마이페이지', link: '/learningcenter/mypage' },
  ];

  return (
    <S.Container>
      <S.NavContainer>
        <S.HeaderContainer>
          <S.IconButton type="button" onClick={handleLogoSection}>
            <S.Logo src={logo} />
          </S.IconButton>
          <S.IconButton type="button" onClick={handleMenuButton}>
            <Icon icon="HamburgerIcon" width={36} height={36} />
          </S.IconButton>
        </S.HeaderContainer>
      </S.NavContainer>
      {isMenuOpen && (
        <S.MenuContainer className={isMenuOpen ? 'visible' : ''}>
          {menuItemList.map(({ label, link }) => (
            <S.ListItem
              onClick={() => {
                handleListItem(link);
              }}
              key={label}
            >
              {label}
            </S.ListItem>
          ))}
          <S.ListItem
            onClick={() => {
              handleLogout();
            }}
          >
            로그아웃
          </S.ListItem>
        </S.MenuContainer>
      )}
    </S.Container>
  );
}
