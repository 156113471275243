import { css } from '@emotion/react';

interface TypographyParams {
  fontWeight?: number;
  fontSize: number;
  lineHeight: number;
}

const generateFont = ({ fontSize, lineHeight, fontWeight = 500 }: TypographyParams) => {
  return css`
    font-family: 'Pretendard';
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    font-weight: ${fontWeight};
  `;
};

export const HeadLine = generateFont({
  fontWeight: 700,
  fontSize: 50,
  lineHeight: 60,
});
export const H1 = generateFont({ fontWeight: 700, fontSize: 34, lineHeight: 40 });
export const H2 = generateFont({ fontWeight: 700, fontSize: 28, lineHeight: 34 });
export const H3 = generateFont({ fontWeight: 700, fontSize: 22, lineHeight: 28 });
export const Title1 = generateFont({ fontWeight: 700, fontSize: 20, lineHeight: 25 });
export const Title2 = generateFont({ fontWeight: 700, fontSize: 17, lineHeight: 22 });
export const Text1 = generateFont({
  fontWeight: 500,
  fontSize: 20,
  lineHeight: 24,
});
export const Text2 = generateFont({ fontWeight: 500, fontSize: 18, lineHeight: 22 });
export const Body1 = generateFont({ fontWeight: 500, fontSize: 17, lineHeight: 22 });
export const Body2 = generateFont({ fontWeight: 500, fontSize: 16, lineHeight: 21 });
export const Body3 = generateFont({ fontWeight: 500, fontSize: 15, lineHeight: 20 });
export const Button1 = generateFont({ fontWeight: 700, fontSize: 16, lineHeight: 20 });
export const Caption1 = generateFont({ fontWeight: 500, fontSize: 13, lineHeight: 18 });

export const OLD_TYPOGRAPHY = {
  HeadLine,
  H1,
  H2,
  H3,
  Title1,
  Title2,
  Text1,
  Text2,
  Body1,
  Body2,
  Body3,
  Button1,
  Caption1,
};
