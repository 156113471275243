import styled from '@emotion/styled';
import { Elevation } from 'styles/theme/elevation';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;

  justify-content: center;
  align-items: center;

  position: fixed;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100vw;
  max-width: 1024px;
  flex-direction: column;

  padding: 40px 24px;
`;

export const Logo = styled.img`
  width: 40px;
  height: 40px;
`;

export const Title = styled.span`
  ${({ theme }) => theme.oldTypography.H2}
  color: ${({ theme }) => theme.colors.black};

  white-space: pre-wrap;
  text-align: left;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 12px;

  margin-bottom: 42px;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;

  gap: 24px;

  margin-bottom: 20px;
`;

export const FindSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 8px;

  margin-bottom: 44px;
`;

export const FindText = styled.a`
  ${({ theme }) => theme.oldTypography.Body3}
  color: ${({ theme }) => theme.colors.black};

  cursor: pointer;

  text-decoration: none;

  &:active {
    color: ${({ theme }) => theme.colors['blue-100']};
  }
`;

export const FindDivider = styled.span`
  ${({ theme }) => theme.oldTypography.Body3}
  color: ${({ theme }) => theme.colors.black};
`;

export const GoogleLoginSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 24px;
`;

export const GoogleButtonContainer = styled.button`
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  ${Elevation.shadow2};

  border: none;
  border-radius: 4px;

  display: flex;
  gap: 24px;

  &:hover {
    ${({ disabled, theme }) =>
      disabled
        ? ''
        : `
        background-color: ${theme.colors.gray6};
    `}
  }
  &:active {
    ${({ disabled, theme }) =>
      disabled
        ? ''
        : `
        background-color: ${theme.colors.gray6};
    `}
  }
`;

export const GoogleIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const GoogleButtonText = styled.span`
  color: #000;

  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;
