import styled from '@emotion/styled';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  overflow-y: auto;

  padding: 0 ${({ theme }) => theme.spacing[20]};
`;

export const Row = styled.div<{ isOdd: boolean }>`
  display: flex;
  align-items: center;

  width: 100%;

  ${({ theme }) => theme.typography['body-medium']};

  background-color: ${({ theme, isOdd }) => (isOdd ? theme.colors.white : theme.colors.gray6)};

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5};
`;

export const Cell = styled.div<{ flex: number; align?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align ?? 'center'};

  flex: ${({ flex }) => flex};

  padding: ${({ theme }) => theme.spacing[8]};
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  svg {
    color: ${({ theme }) => theme.colors['sky-100']};

    &:hover {
      color: ${({ theme }) => theme.colors['sky-80']};
    }
  }
`;
