import styled from '@emotion/styled';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: ${({ theme }) => theme.spacing[28]};

  width: 100%;

  & > hr {
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.gray5};
  }
`;
