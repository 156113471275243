import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  TeachingCenterButtonFlexibility,
  TeachingCenterButtonSize,
  TeachingCenterButtonVariant,
} from './TeachingCenterButton.types';

const getButtonPadding = ({
  theme,
  size = 'medium',
}: {
  theme: Theme;
  size?: TeachingCenterButtonSize;
}) => {
  if (size === 'small') {
    return theme.spacing[8];
  }

  return theme.spacing[12];
};

const getButtonColor = ({
  theme,
  variant,
}: {
  theme: Theme;
  variant: TeachingCenterButtonVariant;
}) => {
  if (variant === 'filled') {
    return {
      backgroundColor: {
        default: theme.colors['blue-100'],
        hover: theme.colors['blue-80'],
        active: theme.colors['blue-60'],
        disabled: theme.colors['blue-20'],
      },
      color: {
        default: theme.colors.white,
        hover: theme.colors.white,
        active: theme.colors.white,
        disabled: theme.colors.white,
      },
      border: {
        default: theme.colors['blue-100'],
        hover: theme.colors['blue-80'],
        active: theme.colors['blue-60'],
        disabled: theme.colors['blue-20'],
      },
    };
  }

  if (variant === 'danger-outlined') {
    return {
      backgroundColor: {
        default: theme.colors.white,
        hover: theme.colors.white,
        active: theme.colors.white,
        disabled: theme.colors.white,
      },
      color: {
        default: theme.colors['red-100'],
        hover: theme.colors['red-80'],
        active: theme.colors['red-60'],
        disabled: theme.colors['red-20'],
      },
      border: {
        default: theme.colors['red-100'],
        hover: theme.colors['red-80'],
        active: theme.colors['red-60'],
        disabled: theme.colors['red-20'],
      },
    };
  }

  return {
    backgroundColor: {
      default: theme.colors.white,
      hover: theme.colors.white,
      active: theme.colors.white,
      disabled: theme.colors.white,
    },
    color: {
      default: theme.colors['blue-100'],
      hover: theme.colors['blue-80'],
      active: theme.colors['blue-60'],
      disabled: theme.colors['blue-20'],
    },
    border: {
      default: theme.colors['blue-100'],
      hover: theme.colors['blue-80'],
      active: theme.colors['blue-60'],
      disabled: theme.colors['blue-20'],
    },
  };
};

export const Container = styled.button<{
  variant: TeachingCenterButtonVariant;
  size?: TeachingCenterButtonSize;
  flexibility?: TeachingCenterButtonFlexibility;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 3px solid ${({ theme, variant }) => getButtonColor({ theme, variant }).border.default};

  width: ${({ flexibility }) => (flexibility === 'flexible' ? '100%' : 'auto')};

  padding: ${getButtonPadding};

  background-color: ${({ theme, variant }) =>
    getButtonColor({ theme, variant }).backgroundColor.default};
  color: ${({ theme, variant }) => getButtonColor({ theme, variant }).color.default};

  cursor: pointer;

  ${({ theme }) => theme.typography['btn-huge']};

  &:hover {
    border-color: ${({ theme, variant }) => getButtonColor({ theme, variant }).border.hover};
    background-color: ${({ theme, variant }) =>
      getButtonColor({ theme, variant }).backgroundColor.hover};
    color: ${({ theme, variant }) => getButtonColor({ theme, variant }).color.hover};
  }

  &:disabled {
    border-color: ${({ theme, variant }) => getButtonColor({ theme, variant }).border.disabled};
    background-color: ${({ theme, variant }) =>
      getButtonColor({ theme, variant }).backgroundColor.disabled};
    color: ${({ theme, variant }) => getButtonColor({ theme, variant }).color.disabled};

    cursor: not-allowed;
  }
`;
