import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StyledToastContainer = styled(ToastContainer)`
  z-index: 4;

  --toastify-toast-offset: 92px;
  --toastify-toast-min-height: 48px;

  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));

  .Toastify__toast {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 360px;
    width: 100%;

    padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[56]};
    border-radius: ${({ theme }) => theme.borderRadius.pill};
    ${({ theme }) => theme.typography['subtitle-small']};
  }

  .Toastify__toast--success {
    background: linear-gradient(0deg, rgba(20, 174, 92, 0.1) 0%, rgba(20, 174, 92, 0.1) 100%), #fff;
    color: ${({ theme }) => theme.colors.green};
    border: 2px solid ${({ theme }) => theme.colors.green};
  }

  .Toastify__toast--error {
    background: linear-gradient(0deg, rgba(242, 72, 34, 0.1) 0%, rgba(242, 72, 34, 0.1) 100%), #fff;
    color: ${({ theme }) => theme.colors.red};
    border: 2px solid ${({ theme }) => theme.colors.red};
  }
`;
