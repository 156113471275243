import { useState } from 'react';
import { putSchedule } from '.';
import { GrowingSchedule } from 'types';

export default function usePutWordCategory() {
  const [isLoading, setIsLoading] = useState(false);

  const putWordCategory = async (schedule: GrowingSchedule) => {
    setIsLoading(true);

    try {
      await putSchedule(schedule);
      setIsLoading(false);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutate: putWordCategory };
}
