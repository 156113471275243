/* eslint-disable react-hooks/exhaustive-deps */
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  updateProfile,
  signOut,
  GoogleAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  EmailAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { doc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import db, { firebaseApp } from '../firebase';
import {
  clearCurrentInfo,
  CURRENTUSER_KEY,
  setCurrentInfo,
} from '../redux/FirebaseData/currentUser/reducer';
import { createUserInfo, deleteUserInfo, updateUserInfo } from './usersApi';
import * as msg from '../lib/utils/msg';

const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();

/**
 * get
 * 현재 로그인한 계정 정보를 currentUser reducer에 저장
 * @returns
 */
export function useAuth() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      if (!user) {
        dispatch(clearCurrentInfo());
        return;
      }
      if (!user.accessToken) {
        dispatch(clearCurrentInfo(CURRENTUSER_KEY.STORE_INFO));
        return;
      }
      dispatch(setCurrentInfo(user, CURRENTUSER_KEY.ACCOUNT_INFO));
      const docRef = doc(db, 'Users', user.uid);
      onSnapshot(docRef, (doc) => {
        dispatch(setCurrentInfo({ ...doc.data(), uid: user.uid }, CURRENTUSER_KEY.STORE_INFO));
      });
    });
    return unsub;
  }, []);
}

export function updateAuth(data) {
  updateProfile(auth.currentUser, data)
    .then(() => {
      updateUserInfo(auth.currentUser.uid, data);
      console.log('success');
    })
    .catch(() => {
      console.log('error!');
    });
}

export function deleteAuth() {
  deleteUserInfo(auth.currentUser.uid)
    .then(() => {
      deleteUser(auth.currentUser);
      console.log('success');
    })
    .catch(() => {
      console.log('error!');
    });
}

export async function signup({ email, password, name, phone, pageType }) {
  const result = await createUserWithEmailAndPassword(auth, email, password);
  updateProfile(result.user, { displayName: name });
  createUserInfo(result.user.uid, {
    name,
    email,
    office: '만덕',
    registrationDate: serverTimestamp(),
    lastLogin: serverTimestamp(),
    phone,
    teacher: pageType === 'teacher' ? true : false,
    allow: false,
    state: false,
    admin: false,
  });
}

export async function login({ email, password }) {
  const result = await signInWithEmailAndPassword(auth, email, password);
  updateUserInfo(result.user.uid, {
    lastLogin: serverTimestamp(),
  });
}

export function logout() {
  return signOut(auth);
}

export async function redirectGoogle() {
  getRedirectResult(auth)
    .then((result) => {
      const user = result.user;

      updateUserInfo(user.uid, {
        name: user.displayName,
        email: user.email,
        lastLogin: serverTimestamp(),
      });
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

export const signGoogle = async () => {
  await new signInWithRedirect(auth, provider);
};

export async function linkwithEmail(email, password) {
  const credential = EmailAuthProvider.credential(email, password);
  linkWithCredential(auth.currentUser, credential)
    .then((usercred) => {
      const user = usercred.user;
      console.log('Account linking success', user);
    })
    .catch((error) => {
      console.log('Account linking error', error);
    });
}

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert(msg.infoMsg.resetPW());
  } catch (error) {
    return alert(msg.errMsg(error));
  }
};
