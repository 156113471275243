import * as S from './ChipButton.styled';

interface ChipButtonProps extends React.ComponentPropsWithoutRef<'button'> {}

export const ChipButton = ({ onClick, children, ...rest }: ChipButtonProps) => {
  return (
    <S.Container onClick={onClick} {...rest}>
      {children}
    </S.Container>
  );
};
