import db from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { GrowingDailyQuiz } from 'types';

export async function getWordQuizRankingByDate(date: string) {
  const dailyQuizCollection = query(collection(db, 'WordQuiz'), where('date', '==', date));
  const querySnapshot = await getDocs(dailyQuizCollection);

  let dailyQuizArray: GrowingDailyQuiz[] = [];
  querySnapshot.forEach((doc) => {
    const dailyQuizData = doc.data() as GrowingDailyQuiz;
    dailyQuizArray.push({ ...dailyQuizData, id: doc.id });
  });

  return dailyQuizArray;
}
