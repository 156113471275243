export const SPACING = {
  '2': '2px',
  '4': '4px',
  '8': '8px',
  '10': '10px',
  '12': '12px',
  '14': '14px',
  '16': '16px',
  '20': '20px',
  '24': '24px',
  '28': '28px',
  '32': '32px',
  '40': '40px',
  '44': '44px',
  '48': '48px',
  '56': '56px',
  '60': '60px',
  '64': '64px',
  '72': '72px',
  '80': '80px',
};
