import styled from '@emotion/styled';
import { makeOpacityColor } from 'styles/theme/colors';
import { ButtonVariant } from './Button.types';
import { Theme } from '@emotion/react';

const buttonStyles = ({ theme, variant }: { theme: Theme; variant: ButtonVariant }) => {
  switch (variant) {
    case 'secondary':
      return {
        color: theme.colors.black,
        border: `2px solid ${theme.colors['blue-100']}`,
        background: theme.colors.white,

        disabledColor: makeOpacityColor('black', 20),
        disabledBorder: `2px solid ${makeOpacityColor('blue-100', 20)}`,
        disabledBackground: makeOpacityColor('white', 20),

        hoverColor: theme.colors.white,
        hoverBackground: theme.colors['blue-100'],
      };
    case 'outline':
      return {
        color: theme.colors.black,
        border: `2px solid ${theme.colors.gray4}`,
        background: theme.colors.white,

        disabledColor: makeOpacityColor('black', 20),
        disabledBorder: `2px solid ${makeOpacityColor('gray4', 20)}`,
        disabledBackground: makeOpacityColor('white', 20),

        hoverColor: theme.colors.black,
        hoverBackground: theme.colors.gray4,
      };
    case 'danger':
      return {
        color: theme.colors.white,
        background: theme.colors.red,

        disabledColor: makeOpacityColor('white', 20),
        disabledBackground: makeOpacityColor('red', 20),

        hoverColor: theme.colors.white,
        hoverBackground: theme.colors.red,
      };
    case 'danger-outline':
      return {
        color: theme.colors.red,
        border: `2px solid ${theme.colors.red}`,
        background: theme.colors.white,

        disabledColor: makeOpacityColor('red', 20),
        disabledBorder: `2px solid ${makeOpacityColor('red', 20)}`,
        disabledBackground: makeOpacityColor('white', 20),

        hoverColor: theme.colors.white,
        hoverBackground: theme.colors.red,
      };
    case 'ghost':
      return {
        color: theme.colors.black,
        background: theme.colors.white,

        disabledColor: makeOpacityColor('black', 20),

        hoverColor: theme.colors.gray2,
      };
    case 'primary':
    default:
      return {
        color: theme.colors.white,
        background: theme.colors['blue-100'],

        disabledColor: theme.colors.white,
        disabledBackground: makeOpacityColor('blue-100', 20),

        hoverColor: theme.colors.white,
        hoverBackground: theme.colors['navy-100'],
      };
  }
};

export const Container = styled.button<{
  variant: ButtonVariant;
  disabled?: boolean;
  fit?: 'content' | 'full';
}>`
  width: ${({ fit }) => (fit === 'content' ? 'fit-content' : '100%')};

  border: ${({ variant, theme }) => buttonStyles({ theme, variant }).border ?? 'none'};
  border-radius: 8px;

  padding: ${({ variant }) => (variant === 'primary' || variant === 'danger' ? '14px' : '12px')};

  background-color: ${({ variant, theme }) => buttonStyles({ theme, variant }).background};
  color: ${({ variant, theme }) => buttonStyles({ theme, variant }).color};
  ${({ theme }) => theme.oldTypography.Button1};

  &:disabled {
    background-color: ${({ variant, theme }) =>
      buttonStyles({ theme, variant }).disabledBackground};
    border: ${({ variant, theme }) => buttonStyles({ theme, variant }).disabledBorder ?? 'none'};
    color: ${({ variant, theme }) => buttonStyles({ theme, variant }).disabledColor};
  }
  &:hover {
    ${({ variant, disabled, theme }) =>
      disabled
        ? ''
        : `
        background-color: ${buttonStyles({ variant, theme }).hoverBackground};
        color: ${buttonStyles({ variant, theme }).hoverColor};
    `}
  }
  &:active {
    ${({ variant, disabled, theme }) =>
      disabled
        ? ''
        : `
        background-color: ${buttonStyles({ variant, theme }).hoverBackground};
        color: ${buttonStyles({ variant, theme }).hoverColor};
    `}
  }
`;
