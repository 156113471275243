import { useCallback, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { GrowingDailyQuiz } from 'types/dailyQuiz';
import { getWordQuizRankingByDate } from '.';

export default function useGetWordQuizRankingByDate(date: string) {
  const [dailyQuiz, setDailyQuiz] = useState<GrowingDailyQuiz[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userUid = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo
    .uid as string;

  const getDailyQuiz = useCallback(async () => {
    if (!userUid) {
      return;
    }
    setIsLoading(true);

    try {
      const dailyQuiz = await getWordQuizRankingByDate(date);
      setDailyQuiz(dailyQuiz);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [userUid, date]);

  useEffect(() => {
    getDailyQuiz();
  }, [getDailyQuiz]);

  return { data: dailyQuiz, isLoading };
}
