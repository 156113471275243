import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing[16]};
`;

export const DateTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1;
`;

export const DateText = styled.h1`
  ${({ theme }) => theme.typography['title-large']};
  color: ${({ theme }) => theme.colors.black};
`;

export const SelectContainer = styled.div`
  flex: 1;
`;
