import styled from '@emotion/styled';
import { Icon } from 'components/atoms';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  justify-content: center;
  align-items: center;
`;

export const NavContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 64px;

  position: fixed;
  top: 0;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

export const HeaderContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1440px;
  height: 100%;

  padding: ${({ theme }) => theme.spacing[14]} ${({ theme }) => theme.spacing[24]}
    ${({ theme }) => theme.spacing[14]} ${({ theme }) => theme.spacing[32]};
`;

export const IconButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 32px;
  height: 32px;
`;

export const MenuIcon = styled(Icon)`
  width: 36px;
  height: 36px;
`;

export const MenuContainer = styled.div`
  position: fixed;
  top: 64px;

  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1440px;

  height: calc(100vh - 64px);

  padding: ${({ theme }) => theme.spacing[32]};

  gap: ${({ theme }) => theme.spacing[16]};

  background-color: ${({ theme }) => theme.colors.white};

  transition: all 0.3s ease-in-out;

  transform: translateY(-100%);

  z-index: 99;

  &.visible {
    transform: translateY(0);
  }
`;

export const ListItem = styled.p`
  ${({ theme }) => theme.typography['subtitle-small']};
  color: ${({ theme }) => theme.colors.gray3};

  text-align: left;

  cursor: pointer;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors['blue-100']};
  }

  transition: all 0.2s ease-in-out;
`;
