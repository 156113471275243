import * as S from './TeachingCenterButton.styled';
import { TeachingCenterButtonProps } from './TeachingCenterButton.types';

export const TeachingCenterButton = ({
  flexibility = 'flexible',
  variant = 'filled',
  size = 'medium',
  children,
  ...props
}: TeachingCenterButtonProps) => {
  return (
    <S.Container type="button" variant={variant} size={size} flexibility={flexibility} {...props}>
      {children}
    </S.Container>
  );
};
