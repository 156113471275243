import { Button, Icon, Radio } from 'components/atoms';
import * as S from './LoadPresetsPopup.styled';
import { useState } from 'react';
import { StudentPreset } from 'types';
import { useDeleteStudentPresets } from 'api/student-preset/useDeleteStudentPresets';
import { useGetStudentPresets } from 'api/student-preset/useGetStudentPresets';
import { Dialog, DialogService, Offcanvas } from 'components/molecules';
import useLoadingIndicator from 'hooks/useLoadingIndicator';
import { ConfirmDeletePopup } from './confirm-delete-popup';
import { ConfirmLoadPopup } from './confirm-load-popup';

interface LoadPresetsPopupProps {
  onLoadStudentsFromPresets: (preset: StudentPreset) => void;
  onClose: () => void;
}

export const LoadPresetsPopup = ({ onLoadStudentsFromPresets, onClose }: LoadPresetsPopupProps) => {
  const [selectedRadio, setSelectedRadio] = useState<StudentPreset['id'] | null>(null);
  const {
    data: studentPresets,
    isLoading: isStudentPresetsLoading,
    refetch: refetchStudentPresets,
  } = useGetStudentPresets();
  const { mutation: deleteStudentPresets, isLoading: isDeleteStudentPresetsLoading } =
    useDeleteStudentPresets();

  const isLoading = isDeleteStudentPresetsLoading || isStudentPresetsLoading;

  const handleRadioChange = (id: string) => {
    setSelectedRadio(id);
  };

  const handleConfirmDelete = async (id: string) => {
    try {
      await deleteStudentPresets(id);
      refetchStudentPresets();
      DialogService.close();
    } catch (e) {
      DialogService.open({
        dialog: (
          <Dialog
            title="삭제에 실패했습니다."
            description={<S.PopupDescription>잠시 후 다시 시도해주세요.</S.PopupDescription>}
            onClose={DialogService.close}
            buttons={[{ children: '확인', onClick: DialogService.close }]}
          />
        ),
      });
    }
  };

  const handleConfirmLoad = () => {
    onLoadStudentsFromPresets(studentPresets.find(({ id }) => id === selectedRadio)!);
    DialogService.close();
    onClose();
  };

  const handleRemoveStudentPreset = async (id: string) => {
    if (isLoading) {
      return;
    }

    DialogService.open({
      dialog: (
        <ConfirmDeletePopup
          onClose={DialogService.close}
          onDelete={() => {
            handleConfirmDelete(id);
          }}
        />
      ),
    });
  };

  const handleConfirmLoadStudents = () => {
    if (!selectedRadio) {
      return;
    }

    DialogService.open({
      dialog: <ConfirmLoadPopup onClose={DialogService.close} onLoad={handleConfirmLoad} />,
    });
  };

  const handleClose = () => {
    onClose();
  };

  useLoadingIndicator({ id: 'load-presets-popup', isShow: isLoading });

  if (studentPresets.length === 0) {
    return (
      <Offcanvas onClose={handleClose}>
        <S.Container>
          <S.Title>학생 정보 불러오기</S.Title>
          <S.NoStudentList>저장된 학생 정보가 없습니다.</S.NoStudentList>
          <Button variant="secondary" onClick={handleClose}>
            돌아가기
          </Button>
        </S.Container>
      </Offcanvas>
    );
  }

  return (
    <Offcanvas onClose={handleClose}>
      <S.Container>
        <S.Title>학생 정보 불러오기</S.Title>
        <S.StudentListTitleContainer>
          <S.StudentListTitleRow>
            <S.StudentListTitleCell>제목</S.StudentListTitleCell>
            <S.StudentListTitleCell>설명</S.StudentListTitleCell>
          </S.StudentListTitleRow>
        </S.StudentListTitleContainer>
        <S.StudentListsContainer>
          {studentPresets.map((studentPreset) => (
            <S.StudentListRow key={studentPreset.id} className="row">
              <S.StudentListRadioButtonLabel htmlFor={studentPreset.id}>
                <Radio
                  id={studentPreset.id}
                  onChange={() => handleRadioChange(studentPreset.id)}
                  checked={selectedRadio === studentPreset.id}
                />
              </S.StudentListRadioButtonLabel>
              <S.StudentListRowCell className="cell">{studentPreset.title}</S.StudentListRowCell>
              <S.StudentListRowCell className="cell">{studentPreset.comment}</S.StudentListRowCell>
              <S.RemoveStudentPresetButton
                onClick={() => handleRemoveStudentPreset(studentPreset.id)}
              >
                <Icon icon="TrashIcon" />
              </S.RemoveStudentPresetButton>
            </S.StudentListRow>
          ))}
        </S.StudentListsContainer>
        <S.ButtonContainer>
          <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
            취소
          </Button>
          <Button disabled={isLoading || !selectedRadio} onClick={handleConfirmLoadStudents}>
            불러오기
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </Offcanvas>
  );
};
