import styled from '@emotion/styled';

export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: ${({ theme }) => theme.spacing[32]};
  padding-right: ${({ theme }) => theme.spacing[28]};
  padding-top: ${({ theme }) => theme.spacing[8]};
  padding-bottom: ${({ theme }) => theme.spacing[8]};
  margin-top: ${({ theme }) => theme.spacing[10]};
  margin-bottom: ${({ theme }) => theme.spacing[10]};
  background-color: ${({ theme }) => theme.colors['blue-100']};

  gap: ${({ theme }) => theme.spacing[4]};

  height: 42px;

  border-top-right-radius: ${({ theme }) => theme.borderRadius['pill']};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius['pill']};

  user-select: none;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors['blue-80']};
  }
`;

export const Logo = styled.img`
  width: 24px;
  height: 24px;
`;

export const Text = styled.span`
  ${({ theme }) => theme.typography['subtitle-regular']};
  color: ${({ theme }) => theme.colors['white']};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: none;
  }
`;
