import { TeachingCenterButton } from 'components/atoms';
import { SideBar, TeachingCenterSelect, TeachingCenterTextInput } from 'components/blocks';
import { Offcanvas, OffcanvasService } from 'components/molecules';
import { useState } from 'react';
import { GrowingSchedule } from 'types';
import { useHandleCreateCategory } from '../../model';
import useLoadingIndicator from 'hooks/useLoadingIndicator';

const branchOptions = [
  { label: '전체', value: 'common' },
  { label: '본관', value: 'mandeok-1' },
  { label: '신관', value: 'mandeok-2' },
];

interface CreateCategoryOffCanvasProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateCategoryOffCanvas = ({ onClose, onSuccess }: CreateCategoryOffCanvasProps) => {
  const { isLoading, mutate: handleCreateCategory } = useHandleCreateCategory({
    onSuccess,
  });

  const isDisabled = isLoading;

  const [categoryValue, setCategoryValue] = useState<GrowingSchedule>({
    id: '',
    name: '',
    subject: 'Competition',
    branch: 'common',
    maxset: 0,
    maxnum: 25,
    minset: 1,
    minnum: 1,
  });

  const handleBranchChange = (value: string) => {
    setCategoryValue((prev) => ({ ...prev, branch: value }));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryValue((prev) => ({ ...prev, name: e.target.value }));
  };

  const handleMinsetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (RegExp(/^\d+$/).test(e.target.value)) {
      const newValue = Number(e.target.value) <= 1 ? 1 : Number(e.target.value);
      setCategoryValue((prev) => ({ ...prev, minset: newValue }));
    }
  };

  const handleMaxnumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryValue((prev) => ({ ...prev, maxnum: Number(e.target.value ?? 25) }));
  };

  const isSubmitButtonDisabled =
    isDisabled ||
    !categoryValue.branch ||
    !categoryValue.name ||
    !categoryValue.minset ||
    !categoryValue.maxnum;

  const handleSubmit = () => {
    if (!categoryValue) {
      return;
    }

    handleCreateCategory(categoryValue);
  };

  useLoadingIndicator({
    id: 'create-word-category',
    isShow: isLoading,
  });

  return (
    <Offcanvas onClose={onClose}>
      <SideBar title="Create Category" description="카테고리 정보를 입력하세요.">
        <SideBar.Content>
          <SideBar.Form onSubmit={handleSubmit}>
            <TeachingCenterSelect
              label="지점"
              options={branchOptions}
              value={categoryValue?.branch ?? 'common'}
              onChange={handleBranchChange}
              disabled
              helperText="지점 선택 기능은 추후 추가 예정입니다."
            />
            <hr />
            <TeachingCenterTextInput
              label="카테고리 이름"
              value={categoryValue?.name ?? ''}
              onChange={handleNameChange}
              placeholder="카테고리 이름을 입력하세요."
              disabled={isDisabled}
            />
            <hr />
            <TeachingCenterTextInput
              label="세트 시작 번호"
              value={categoryValue?.minset ?? 1}
              onChange={handleMinsetChange}
              helperText="입력된 숫자부터 세트 번호가 시작됩니다."
              disabled={isDisabled}
            />
            <hr />
            <TeachingCenterTextInput
              label="최대 단어 개수"
              value={categoryValue?.maxnum ?? 25}
              onChange={handleMaxnumChange}
              disabled
              rightItem={'개'}
              helperText="최대 단어 개수는 수정할 수 없습니다."
            />
          </SideBar.Form>
        </SideBar.Content>
        <SideBar.ButtonContainer>
          <TeachingCenterButton variant="outlined" onClick={onClose} disabled={isDisabled}>
            취소
          </TeachingCenterButton>
          <TeachingCenterButton
            variant="filled"
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
          >
            등록 완료
          </TeachingCenterButton>
        </SideBar.ButtonContainer>
      </SideBar>
    </Offcanvas>
  );
};
