import usePostWordCategory from 'api/schedule/usePostWordCategory';
import { showToast } from 'components/blocks';
import { GrowingSchedule } from 'types';

interface UseHandleCreateCategoryProps {
  onSuccess: () => void;
  onError?: () => void;
}

export function useHandleCreateCategory({ onSuccess, onError }: UseHandleCreateCategoryProps) {
  const { isLoading, mutate: postWordCategory } = usePostWordCategory();

  const handleCreateCategory = async (category: GrowingSchedule) => {
    try {
      await postWordCategory(category);
      onSuccess();
      showToast({
        type: 'success',
        message: '카테고리가 생성되었습니다.',
      });
    } catch (error) {
      onError?.();
      showToast({
        type: 'error',
        message: '오류가 발생했습니다. 다시 시도해주세요.',
      });
    }
  };

  return { isLoading, mutate: handleCreateCategory };
}
