export type NavigationMenu = {
  label: string;
  href: string;
  description: string;
  isAdmin: boolean;
  subMenus: {
    label: string;
    menus: {
      label: string;
      href: string;
      description: string;
    }[];
  }[];
};

export const NAVIGATION_MENUS: NavigationMenu[] = [
  {
    label: 'Student',
    href: '/teachingcenter/student',
    description: '원생을 관리합니다.',
    isAdmin: false,
    subMenus: [
      {
        label: '학생 관리',
        menus: [
          {
            label: 'Graph',
            href: '/teachingcenter/students',
            description: '학생의 그래프를 확인합니다.',
          },
          {
            label: 'List',
            href: '/teachingcenter/students/list',
            description: '학생의 목록을 확인합니다.',
          },
        ],
      },
    ],
  },
  {
    label: 'Test',
    href: '/teachingcenter/test',
    description: '본문을 등록, 수정, 삭제하거나\n데일리 테스트 결과를 관리합니다.',
    isAdmin: false,
    subMenus: [
      {
        label: '점수',
        menus: [
          {
            label: 'Input Score',
            href: '/teachingcenter/test/input',
            description: '점수를 입력합니다.',
          },
          {
            label: 'Modify Score',
            href: '/teachingcenter/test/modify',
            description: '점수를 수정, 삭제합니다.',
          },
        ],
      },
    ],
  },
  {
    label: 'Quiz',
    href: '/teachingcenter/quiz',
    description: '퀴즈를 등록, 수정, 삭제하거나\n퀴즈 결과를 관리합니다.',
    isAdmin: false,
    subMenus: [
      {
        label: '퀴즈 결과 관리',
        menus: [
          {
            label: 'Input Quiz Result',
            href: '/teachingcenter/quiz/input',
            description: '퀴즈 결과를 입력합니다.',
          },
          {
            label: 'Review Note',
            href: '/teachingcenter/quiz/review-note',
            description: '오답 노트를 확인합니다.',
          },
        ],
      },
    ],
  },
  {
    label: 'Competition',
    href: '/teachingcenter/competition',
    description: '컴퍼티션 게임을 하거나\n게임 결과를 관리합니다.',
    isAdmin: false,
    subMenus: [
      {
        label: '컴퍼티션',
        menus: [
          {
            label: 'Start Game',
            href: '/teachingcenter/competition',
            description: '컴퍼티션 게임을 시작합니다.',
          },
          {
            label: 'Modify Result',
            href: '/teachingcenter/competition/modify',
            description: '게임 결과를 수정합니다.',
          },
        ],
      },
    ],
  },
  {
    label: 'Schedule',
    href: '/teachingcenter/schedule',
    description: '일정을 관리합니다.',
    isAdmin: false,
    subMenus: [
      {
        label: '스케쥴 관리',
        menus: [
          {
            label: 'Schedule',
            href: '/teachingcenter/schedule',
            description: '일정을 관리합니다.',
          },
        ],
      },
    ],
  },
  {
    label: 'Tool',
    href: '/teachingcenter/tool',
    description: '학습에 필요한 도구 페이지입니다.',
    isAdmin: false,
    subMenus: [
      {
        label: '도구',
        menus: [
          {
            label: 'Stopwatch',
            href: '/teachingcenter/tool/stopwatch',
            description: '스톱워치를 사용합니다.',
          },
          {
            label: 'Voca',
            href: '/teachingcenter/tool/voca',
            description: '단어를 연습합니다.',
          },
          {
            label: 'Upload Todo List',
            href: '/teachingcenter/tool/todo',
            description: '학생의 Todo List를 등록합니다.',
          },
          {
            label: 'Todo Check',
            href: '/teachingcenter/tool/todo-check',
            description: '학생의 Todo List 수행 여부를 확인합니다.',
          },
        ],
      },
    ],
  },
  {
    label: 'Admin',
    href: '/teachingcenter/admin',
    description: '관리자 페이지입니다.',
    isAdmin: true,
    subMenus: [
      {
        label: '수치 조정',
        menus: [
          {
            label: 'Setting',
            href: '/teachingcenter/admin/setting',
            description: '각종 수치를 조정합니다.',
          },
        ],
      },
      {
        label: '퀴즈 결과 확인',
        menus: [
          {
            label: 'Daily Quiz Results',
            href: '/teachingcenter/admin/daily-quiz-results',
            description: '오늘 학생들이 실시한 단어 퀴즈의 결과를 확인합니다.',
          },
        ],
      },
      {
        label: '퀴즈 관리',
        menus: [
          {
            label: 'Upload Question',
            href: '/teachingcenter/admin/question/upload',
            description: '퀴즈를 업로드합니다.',
          },
          {
            label: 'Modify Question',
            href: '/teachingcenter/admin/question/modify',
            description: '퀴즈를 수정합니다.',
          },
        ],
      },
      {
        label: '본문 관리',
        menus: [
          {
            label: 'Upload Passage',
            href: '/teachingcenter/admin/passage/upload',
            description: '본문을 업로드합니다.',
          },
          {
            label: 'Modify Passage',
            href: '/teachingcenter/admin/passage/modify',
            description: '본문을 수정합니다.',
          },
        ],
      },
      {
        label: '단어 관리',
        menus: [
          {
            label: 'Upload Word',
            href: '/teachingcenter/admin/word/upload',
            description: '단어를 업로드합니다.',
          },
          {
            label: 'Modify Word',
            href: '/teachingcenter/admin/word/modify',
            description: '단어를 수정합니다.',
          },
          {
            label: 'Category',
            href: '/teachingcenter/admin/word/category',
            description: '단어의 카테고리를 관리합니다.',
          },
        ],
      },
      {
        label: '납부 관리',
        menus: [
          {
            label: 'Payment',
            href: '/teachingcenter/admin/payment',
            description: '납부 기록을 관리합니다.',
          },
        ],
      },
    ],
  },
  {
    label: 'System',
    href: '/teachingcenter/system',
    description: '시스템 관리 페이지입니다.',
    isAdmin: true,
    subMenus: [
      {
        label: '시스템',
        menus: [
          {
            label: 'System',
            href: '/teachingcenter/system',
            description: '시스템 관리 페이지입니다.',
          },
        ],
      },
    ],
  },
];
