import {
  SideBar,
  TeachingCenterCheckbox,
  TeachingCenterSelect,
  TeachingCenterTextInput,
} from 'components/blocks';
import { Offcanvas } from 'components/molecules';
import { useEffect, useMemo, useState } from 'react';
import { GrowingSchedule } from 'types';
import { useHandleDeleteCategory, useHandleModifyCategory } from '../../model';
import { TeachingCenterButton } from 'components/atoms';

const branchOptions = [
  { label: '전체', value: 'common' },
  { label: '본관', value: 'mandeok-1' },
  { label: '신관', value: 'mandeok-2' },
];

interface ModifyCategoryOffCanvasProps {
  category: GrowingSchedule;
  onClose: () => void;
  onSuccess: () => void;
}

export const ModifyCategoryOffCanvas = ({
  category,
  onClose,
  onSuccess,
}: ModifyCategoryOffCanvasProps) => {
  const [categoryValue, setCategoryValue] = useState<GrowingSchedule | null>(null);
  const [isDelete, setIsDelete] = useState(false);

  const { handleModifyCategory, isLoading: isModifyLoading } = useHandleModifyCategory({
    onSuccess,
  });

  const { handleDeleteCategory, isLoading: isDeleteLoading } = useHandleDeleteCategory({
    onSuccess,
  });

  const isApiLoading = isModifyLoading || isDeleteLoading;

  const handleDeleteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDelete(e.target.checked);
  };

  const handleBranchChange = (value: string) => {
    setCategoryValue((prev) => (prev ? { ...prev, branch: value } : null));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryValue((prev) => (prev ? { ...prev, name: e.target.value } : null));
  };

  const handleMinsetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (RegExp(/^\d+$/).test(e.target.value)) {
      const newValue = Number(e.target.value) <= 1 ? 1 : Number(e.target.value);
      setCategoryValue((prev) => (prev ? { ...prev, minset: newValue } : null));
    }
  };

  const handleMaxnumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryValue((prev) => (prev ? { ...prev, maxnum: Number(e.target.value ?? 25) } : null));
  };

  const isValueChanged = useMemo(() => {
    return (
      categoryValue?.name !== category.name ||
      categoryValue?.minset !== category.minset ||
      categoryValue?.maxnum !== category.maxnum
    );
  }, [categoryValue, category]);

  const isSubmitButtonDisabled = useMemo(() => {
    return !isValueChanged && !isDelete;
  }, [isValueChanged, isDelete]);

  const handleSubmit = () => {
    if (!categoryValue) {
      return;
    }

    if (isDelete) {
      handleDeleteCategory(categoryValue);
    } else {
      handleModifyCategory(categoryValue);
    }
  };

  useEffect(() => {
    setCategoryValue(category);
  }, [category]);

  return (
    <Offcanvas onClose={onClose}>
      <SideBar title="Update Category" description="카테고리 정보를 입력하세요.">
        <SideBar.Content>
          <SideBar.Form onSubmit={handleSubmit}>
            <TeachingCenterSelect
              label="지점"
              options={branchOptions}
              value={categoryValue?.branch ?? 'common'}
              onChange={handleBranchChange}
              disabled
              helperText="지점 선택 기능은 추후 추가 예정입니다."
            />
            <hr />
            <TeachingCenterTextInput
              label="카테고리 이름"
              value={categoryValue?.name ?? ''}
              onChange={handleNameChange}
              placeholder="카테고리 이름을 입력하세요."
              disabled={isApiLoading}
              tabIndex={1}
            />
            <hr />
            <TeachingCenterTextInput
              label="세트 시작 번호"
              value={categoryValue?.minset ?? 1}
              onChange={handleMinsetChange}
              helperText="입력된 숫자부터 세트 번호가 시작됩니다."
              disabled={isApiLoading}
              tabIndex={2}
            />
            <hr />
            <TeachingCenterTextInput
              label="최대 단어 개수"
              value={categoryValue?.maxnum ?? 25}
              onChange={handleMaxnumChange}
              readOnly
              rightItem={'개'}
              helperText="최대 단어 개수는 수정할 수 없습니다."
            />
          </SideBar.Form>
        </SideBar.Content>
        <SideBar.CheckboxContainer>
          <TeachingCenterCheckbox
            label="카테고리를 삭제하시겠습니까?"
            checked={isDelete}
            onChange={handleDeleteChange}
            disabled={isApiLoading}
            tabIndex={3}
          />
        </SideBar.CheckboxContainer>
        <SideBar.ButtonContainer>
          <TeachingCenterButton
            variant="outlined"
            onClick={onClose}
            disabled={isApiLoading}
            tabIndex={4}
          >
            취소
          </TeachingCenterButton>
          <TeachingCenterButton
            variant="filled"
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
            tabIndex={5}
          >
            수정 완료
          </TeachingCenterButton>
        </SideBar.ButtonContainer>
      </SideBar>
    </Offcanvas>
  );
};
