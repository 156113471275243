import { forwardRef } from 'react';
import * as S from './TeachingCenterTextInput.styled';

interface TextInputProps extends React.ComponentProps<'input'> {
  label: string;
  rightItem?: React.ReactNode;
  helperText?: string;
  readOnly?: boolean;
}

export const TeachingCenterTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      label,
      rightItem = null,
      disabled = false,
      helperText = '',
      readOnly = false,
      onChange,
      ...props
    },
    ref,
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (readOnly) {
        return;
      }

      onChange?.(e);
    };

    return (
      <S.Container>
        <label id={id}>{label}</label>
        <S.ContentContainer>
          <S.InputContainer disabled={disabled} readOnly={readOnly}>
            <S.Input id={id} disabled={disabled} ref={ref} {...props} onChange={handleChange} />
            {rightItem}
          </S.InputContainer>
          {helperText && <S.HelperText>{helperText}</S.HelperText>}
        </S.ContentContainer>
      </S.Container>
    );
  },
);
