/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { ButtonGroup, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import OptionAccordion from '../../../../Component/Schedule/Update/OptionAccordion';
import ScheduleLoadButton from '../../../../Component/Schedule/Update/ScheduleLoadButton';
import ClearButton from '../../../../Component/Schedule/Input/ClearButton';
import InputTable from '../../../../Component/Schedule/Update/InputTable';
import AddButton from '../../../../Component/Schedule/Update/AddButton';
import { selOptionClear } from '../../../../redux/selOption/actions';
import AlertCard from '../../../../Component/Schedule/Update/AlertCard';
import { loadingFalse } from '../../../../redux/Loading/actions';
import UpdateButton from '../../../../Component/Schedule/Update/UpdateButton';
import { inputDataClear } from '../../../../redux/InputData/actions';
import { getFireStoreData } from '../../../../redux/FirebaseData/DownloadController/reducer';
import usePageBlock from '../../../../hooks/usePageBlock';
import useRedirect from '../../../../hooks/useRedirect';

import * as S from 'components/templates/common-container/CommonContainer.styled';
import BasicLayout from 'components/templates/basic-layout';

export const ScheduleUpdate = () => {
  // redux dispatch
  const dispatch = useDispatch();

  // reducers
  // 업로드할 스케쥴을 저장하는 데이터 reducer
  const inputData = useSelector((state) => state.inputDataReducer);
  // download controler reducer
  const downloadSchedule = useSelector((state) => state.downloadControlerReducer).schedule;

  // inputData 내 데이터가 있으면 페이지 이탈 방지
  usePageBlock(inputData.length);

  useRedirect('teachingcenter', 'home');

  // 컨테이너 마운트 시 최초 한번만 실행
  useEffect(() => {
    // select option reducer를 스케쥴 인풋에 맞게 재설정
    dispatch(selOptionClear('ScheduleInput'));
    // schedule inputData 초기화
    dispatch(inputDataClear());
    dispatch(loadingFalse());
    // 스케쥴 데이터 다운로드 토글 true로
    !downloadSchedule && dispatch(getFireStoreData('schedule'));
  }, []);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <OptionAccordion />
          <AlertCard />

          <Row className="px-2 mx-3">
            <hr />
            <Col>
              <ButtonGroup style={{ float: 'right' }}>
                <ScheduleLoadButton />
                <UpdateButton />
                <ClearButton />
              </ButtonGroup>
            </Col>
          </Row>
          <Row className="g-4 pb-2 mx-3">
            <Col>
              <hr />
            </Col>
          </Row>
          <Row className="g-4 mb-5 pb-2 mx-3">
            <Col>
              <InputTable />
              <AddButton />
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default ScheduleUpdate;
