import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 60px;
  left: 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: fit-content;
  min-height: 260px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);

  border-top: 1px solid ${({ theme }) => theme.colors.gray6};

  padding-left: 220px;
  padding-top: ${({ theme }) => theme.spacing[20]};
  padding-bottom: ${({ theme }) => theme.spacing[32]};
  padding-right: 130px;

  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;

  ${({ theme }) => theme.typography['subtitle-small']};

  &.visible {
    visibility: visible;
    transform: translateY(0);
  }
`;

export const ContentContainer = styled.div<{ isFading?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  max-width: 1000px;
  height: 100%;

  gap: ${({ theme }) => theme.spacing[60]};

  animation: ${({ isFading }) => (isFading ? fadeIn : 'none')} 0.2s ease-in-out;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Title = styled.h2`
  margin: 0;
  ${({ theme }) => theme.typography['title-large']};
  color: ${({ theme }) => theme.colors.gray2};
`;

export const Description = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography['body-large']};
  color: ${({ theme }) => theme.colors.gray3};

  text-align: left;

  white-space: pre-line;
`;

export const SubMenuContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  padding-top: ${({ theme }) => theme.spacing[8]};

  gap: ${({ theme }) => theme.spacing[40]};

  flex: 1;

  width: 100%;
`;

export const SubMenuCategory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: ${({ theme }) => theme.spacing[8]};
`;

export const SubMenuCategoryTitle = styled.h3`
  margin: 0;
  ${({ theme }) => theme.typography['title-tiny']};
  color: ${({ theme }) => theme.colors.gray3};
`;

export const SubMenu = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  ${({ theme }) => theme.typography['body-large']};
  color: ${({ theme }) => theme.colors.gray4};
  text-decoration: none;

  padding: 0;

  transition: all 0.2s ease-in-out;

  &.selected {
    color: ${({ theme }) => theme.colors['blue-100']};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.gray3};
    transform: scale(1.02);

    &.selected {
      color: ${({ theme }) => theme.colors['blue-100']};
    }
  }
`;
