import { useEffect, useMemo, useState } from 'react';
import { NAVIGATION_MENUS } from './constants';
import { RootStateOrAny, useSelector } from 'react-redux';
import { User } from 'types';
import { WebTeachingCenterNavigation } from './web-navigation/WebTeachingCenterNavigation';
import { MobileTeachingCenterNavigation } from './mobile-navigation/MobileTeachingCenterNavigation';
import { NavigationMenu } from './constants/menu-list';

interface TeachingCenterNavigationProps {
  handleLogout?: () => Promise<void>;
}

export const TeachingCenterNavigation = ({ handleLogout }: TeachingCenterNavigationProps) => {
  const [isWeb, setIsWeb] = useState(false);

  useEffect(() => {
    const checkIsWeb = () => {
      setIsWeb(window.innerWidth > 1024);
    };

    checkIsWeb();

    window.addEventListener('resize', () => {
      checkIsWeb();
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const myInfo = useSelector((state: RootStateOrAny) => state.currentUserReducer).storeInfo as User;

  const menus: NavigationMenu[] = useMemo(() => {
    return NAVIGATION_MENUS.filter((menu) => {
      if (menu.isAdmin) {
        return myInfo.admin;
      }
      return true;
    });
  }, [myInfo]);

  return isWeb ? (
    <WebTeachingCenterNavigation menus={menus} handleLogout={handleLogout} />
  ) : (
    <MobileTeachingCenterNavigation menus={menus} handleLogout={handleLogout} />
  );
};
