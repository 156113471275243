import { GrowingSchedule } from 'types';
import { getScheduleConfig, postSchedule, updateScheduleConfig } from '.';
import { useState } from 'react';

export default function usePostWordCategory() {
  const [isLoading, setIsLoading] = useState(false);

  const postWordCategory = async (schedule: GrowingSchedule) => {
    setIsLoading(true);
    try {
      const config = await getScheduleConfig();
      await postSchedule({ ...schedule, id: `MDC${config.competitionIdCount + 1}` });
      await updateScheduleConfig({
        competitionIdCount: config.competitionIdCount + 1,
      });
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutate: postWordCategory };
}
