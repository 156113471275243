/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useRedirect from '../../../../../hooks/useRedirect';
import { groupBy } from '../../../../../lib/utils/getRank';
import BasicLayout from 'components/templates/basic-layout';
import * as PS from 'components/templates/common-container/CommonContainer.styled';
import * as S from './DailyQuizResultsPage.styled';

import * as CardStyles from '../../../../../styles/CardStyles';
import useGetWordQuizRankingByDate from 'api/daily-quiz/useGetWordQuizRankingByDate';
import useGetGrowingClasses from 'api/class/useGetGrowingClasses';
import { TeachingCenterSelect } from 'components/blocks';
import { GrowingDailyQuiz } from 'types';
import useLoadingIndicator from 'hooks/useLoadingIndicator';

const todayDate = moment(new Date()).format('YYYY-MM-DD');

export const DailyQuizResultPage = () => {
  const { data: classes, isLoading: isClassesLoading } = useGetGrowingClasses();
  const { data: dailyQuiz, isLoading: isDailyQuizLoading } = useGetWordQuizRankingByDate(todayDate);

  const [classOption, setClassOption] = useState<{ label: string; value: string }>();
  const [classOptions, setClassOptions] = useState<{ label: string; value: string }[]>([]);
  const [rankingList, setRankingList] = useState<GrowingDailyQuiz[]>([]);
  const [classRankingList, setClassRankingList] = useState<
    { sid: string; name: string; score: number; correct: number }[]
  >([]);

  const changeNowClass = (value: string) => {
    const selectedClass = classOptions.find((e) => e.value === value);

    if (selectedClass) {
      setClassOption({ label: selectedClass.label, value });
      setClassRankingList(
        groupBy(
          rankingList.filter((data) => data.cid === selectedClass.value),
          'sid',
        ),
      );
    }
  };

  useRedirect('learningcenter', 'home');

  useLoadingIndicator({
    id: 'daily-quiz-result',
    isShow: isClassesLoading || isDailyQuizLoading,
  });

  useEffect(() => {
    if (classes && classes.length > 0 && dailyQuiz) {
      setClassOptions(classes.map((e) => ({ label: e.name, value: e.id })));
      setClassOption({ label: classes[0].name, value: classes[0].id });
      setRankingList(dailyQuiz);
      setClassRankingList(
        groupBy(
          dailyQuiz.filter((quiz) => quiz.cid === classes[0].id),
          'sid',
        ),
      );
    }
  }, [classes, dailyQuiz]);

  return (
    <BasicLayout headerType="teacher">
      <PS.Container>
        <PS.ContentContainer>
          <S.HeaderContainer>
            <S.DateTextContainer>
              <S.DateText>{`${todayDate} Ranking`} </S.DateText>
            </S.DateTextContainer>
            {classes && classOption && (
              <S.SelectContainer>
                <TeachingCenterSelect
                  value={classOption.label}
                  options={classOptions}
                  onChange={changeNowClass}
                />
              </S.SelectContainer>
            )}
          </S.HeaderContainer>
          <Row className="mt-4">
            <Col>
              <CardStyles.CardContainer more>
                <CardStyles.CardQuizResultRow quizResult>
                  <div>순위</div>
                  <div>이름</div>
                  <div>점수</div>
                  <div>정답 갯수</div>
                </CardStyles.CardQuizResultRow>
                {classRankingList.map((result, i) => (
                  <CardStyles.CardQuizResultRow quizResult key={i}>
                    <div>{i + 1}</div>
                    <div>{result.name}</div>
                    <div>{result.score}</div>
                    <div>{result.correct}</div>
                  </CardStyles.CardQuizResultRow>
                ))}
              </CardStyles.CardContainer>
            </Col>
          </Row>
        </PS.ContentContainer>
      </PS.Container>
    </BasicLayout>
  );
};
