import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './MobileTeachingCenterNavigation.styled';
import { TopNavigationLogo } from '../top-navigation-logo';
import { useState } from 'react';
import { NavigationMenu } from '../constants/menu-list';
import { Icon } from 'components/atoms';

interface TeachingCenterNavigationProps {
  menus: NavigationMenu[];
  handleLogout?: () => Promise<void>;
}

export const MobileTeachingCenterNavigation = ({
  menus,
  handleLogout,
}: TeachingCenterNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<NavigationMenu | null>(null);

  const isFirstStep = !selectedMenu;

  const handleCloseMenu = () => {
    setIsOpen(false);

    setTimeout(() => {
      setSelectedMenu(null);
    }, 200);
  };

  const handleMenuButtonClick = () => {
    setIsOpen((prev) => !prev);

    setTimeout(() => {
      setSelectedMenu(null);
    }, 200);
  };

  const handleMenuClick = (menu: NavigationMenu) => {
    setSelectedMenu(menu);
  };

  const handleSubMenuClick = (href: string) => {
    navigate(href);
    handleCloseMenu();
  };

  return (
    <S.Container>
      <S.NavContainer>
        <TopNavigationLogo />
        <S.IconButton type="button" onClick={handleMenuButtonClick}>
          <Icon icon="HamburgerIcon" width={36} height={36} />
        </S.IconButton>
      </S.NavContainer>
      <S.NavigationMenuContainer className={isOpen ? 'visible' : ''}>
        {isFirstStep
          ? menus.map((menu) => (
              <S.NavigationMenu
                className={location.pathname.includes(menu.href) ? 'selected' : ''}
                key={menu.label}
                onClick={() => handleMenuClick(menu)}
              >
                {menu.label}
              </S.NavigationMenu>
            ))
          : selectedMenu?.subMenus.map((subMenu) => (
              <S.NavigationSubMenuContainer>
                <S.NavigationLabel>{subMenu.label}</S.NavigationLabel>
                {subMenu.menus.map((menu) => (
                  <S.NavigationMenu
                    key={menu.label}
                    className={location.pathname.includes(menu.href) ? 'selected' : ''}
                    onClick={() => handleSubMenuClick(menu.href)}
                  >
                    {menu.label}
                  </S.NavigationMenu>
                ))}
              </S.NavigationSubMenuContainer>
            ))}
      </S.NavigationMenuContainer>
      {/* <SubMenuContainer isVisible={isHovered} hoveredMenu={hoveredMenu} /> */}
    </S.Container>
  );
};
