import { Icon } from 'components/atoms';
import * as S from './EmptyCategory.styled';

export const EmptyCategory = () => {
  return (
    <S.EmptyContainer>
      <Icon icon="CautionIcon" />
      <S.EmptyText>조회할 데이터가 없습니다.</S.EmptyText>
    </S.EmptyContainer>
  );
};
