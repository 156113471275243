import { ChipButton, Icon, TeachingCenterButton } from 'components/atoms';
import { SideBar, TeachingCenterSelect, TeachingCenterTextInput } from 'components/blocks';
import * as S from './FilterCategorySideBar.styled';
import { useRef } from 'react';
import { handleCreateCategoryOffcanvasOpen } from '../../model';

interface FilterCategorySidebarProps {
  branch: string;
  branchOptions: { label: string; value: string }[];
  categoryName: string;
  onBranchChange: (value: string) => void;
  onCategoryNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: (e: React.FormEvent<HTMLFormElement>) => void;
  onReset: () => void;
  refetchWhenCreateCategorySuccess: () => void;
  isCreateButtonDisabled: boolean;
  isShowResetButton: boolean;
}

export const FilterCategorySideBar = ({
  branch,
  branchOptions,
  categoryName,
  onBranchChange,
  onCategoryNameChange,
  onSearch,
  onReset,
  refetchWhenCreateCategorySuccess,
  isCreateButtonDisabled,
  isShowResetButton,
}: FilterCategorySidebarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleReset = () => {
    onReset();
    inputRef.current?.focus();
  };

  const handleButtonClick = () => {
    handleCreateCategoryOffcanvasOpen({
      onSuccess: refetchWhenCreateCategorySuccess,
    });
  };

  return (
    <SideBar title="Category">
      <SideBar.Content>
        <SideBar.Form onSubmit={onSearch}>
          <TeachingCenterSelect
            label="지점"
            value={branch}
            options={branchOptions}
            onChange={onBranchChange}
            readonly
            helperText="지점 선택 기능은 추후 추가 예정입니다."
          />
          <TeachingCenterTextInput
            ref={inputRef}
            label="카테고리 이름"
            value={categoryName}
            onChange={onCategoryNameChange}
            placeholder="카테고리 이름으로 검색하세요."
            rightItem={<Icon icon="SearchIcon" />}
            tabIndex={1}
          />
          {isShowResetButton && (
            <S.SidebarButtonContainer>
              <ChipButton tabIndex={2} onClick={handleReset}>
                <S.SidebarButtonChildren>
                  <Icon icon="ResetIcon" />
                  필터 초기화
                </S.SidebarButtonChildren>
              </ChipButton>
            </S.SidebarButtonContainer>
          )}
        </SideBar.Form>
      </SideBar.Content>
      <SideBar.ButtonContainer>
        <TeachingCenterButton
          variant="outlined"
          onClick={handleButtonClick}
          disabled={isCreateButtonDisabled}
        >
          카테고리 등록
        </TeachingCenterButton>
      </SideBar.ButtonContainer>
    </SideBar>
  );
};
