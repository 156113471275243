import { useState } from 'react';
import { updateConfig } from '.';
import { UpdateConfigRequest } from 'types/config';

export const usePutConfig = () => {
  const [isLoading, setIsLoading] = useState(false);

  const putConfig = async ({ id, data }: UpdateConfigRequest) => {
    setIsLoading(true);

    try {
      await updateConfig({ id, data });
      setIsLoading(false);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutate: putConfig };
};
