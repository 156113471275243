import styled from '@emotion/styled';

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacing[12]};

  width: 100%;
  height: 100%;
`;

export const EmptyText = styled.div`
  ${({ theme }) => theme.typography['body-medium']};
`;
