import { useState, useCallback, useEffect } from 'react';
import { getSchedule } from '.';
import { GrowingSchedule } from 'types';

export default function useGetWordCategory() {
  const [data, setData] = useState<GrowingSchedule[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getScheduleInfo = useCallback(async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const schedule = await getSchedule();
      setData(schedule);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const refetch = useCallback(() => {
    getScheduleInfo();
  }, [getScheduleInfo]);

  useEffect(() => {
    getScheduleInfo();
  }, [getScheduleInfo]);

  return { data, isLoading, refetch, isError };
}
