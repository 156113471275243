import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  gap: ${({ theme }) => theme.spacing[12]};
`;
