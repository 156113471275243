import styled from '@emotion/styled';

export const Description = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography['body-large']};
  color: ${({ theme }) => theme.colors.black};

  white-space: pre-wrap;
  word-break: break-word;

  text-align: center;

  .span {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const RedText = styled.span`
  color: ${({ theme }) => theme.colors.red};
`;
