import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: ${({ theme }) => theme.spacing[12]};

  width: 100%;

  label {
    ${({ theme }) => theme.typography['title-tiny']};
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  gap: ${({ theme }) => theme.spacing[4]};
`;

export const Select = styled.button<{ readonly?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => theme.spacing[16]} ${({ theme }) => theme.spacing[12]};

  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.black};

  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.typography['body-medium']};
  color: ${({ theme }) => theme.colors.black};

  ${({ readonly, theme }) =>
    readonly &&
    css`
      background-color: ${theme.colors.gray6};
      color: ${theme.colors.gray3};
      border: none;
      cursor: not-allowed;
    `}

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray6};
    color: ${({ theme }) => theme.colors.gray3};
    border: none;
    cursor: not-allowed;
  }
`;

export const HelperText = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography['caption-regular']};
  color: ${({ theme }) => theme.colors.black};
`;

export const OptionContainer = styled.div`
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translateY(100%);

  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.spacing[10]} 0;

  width: 100%;

  border: 1px solid ${({ theme }) => theme.colors.black};

  background-color: ${({ theme }) => theme.colors.white};

  z-index: 99;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[12]};

  ${({ theme }) => theme.typography['body-medium']};
  color: ${({ theme }) => theme.colors.black};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
  }
`;
