import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import useGetConfig from 'api/config/useGetConfig';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #4b82fa;
  border-radius: 8px;
  margin-top: 16px;
  padding: 0px 8px 16px;
  overflow: hidden;

  .swiper {
    margin-top: 16px;
    position: relative;
  }
  .swiper .swiper-pagination {
    position: absolute;
    bottom: -4px;
  }
`;

export default function NoticeCard() {
  const [bannerData, setBannerData] = useState([]);

  const { data: config } = useGetConfig();

  useEffect(() => {
    if (!config?.studentHomeBanner) {
      return;
    }

    setBannerData(config?.studentHomeBanner ?? []);
  }, [config]);

  if (bannerData.length === 0 || bannerData[0].text === '') {
    return null;
  }

  return (
    <Container>
      <Swiper
        modules={[Autoplay]}
        centeredSlides
        spaceBetween={10}
        slidesPerView={1}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
      >
        {bannerData.map(({ text: bannerText }) => (
          <SwiperSlide key={bannerText}>{bannerText ?? 'sample'}</SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}
