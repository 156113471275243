import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  overflow: hidden;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;

  width: 100%;
  height: calc(100vh - 60px);

  padding: ${({ theme }) => theme.spacing[20]};
`;

export const DataTable = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  display: flex;
  align-items: center;

  width: 100%;
  height: 36px;

  background-color: ${({ theme }) => theme.colors.gray5};

  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[20]};

  ${({ theme }) => theme.typography['subtitle-tiny']};
`;

export const Row = styled.div<{ isOdd: boolean }>`
  display: flex;
  align-items: center;

  width: 100%;

  ${({ theme }) => theme.typography['body-medium']};

  background-color: ${({ theme, isOdd }) => (isOdd ? theme.colors.white : theme.colors.gray6)};

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5};
`;

export const Cell = styled.div<{ flex: number; align?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align ?? 'center'};

  flex: ${({ flex }) => flex};

  padding: ${({ theme }) => theme.spacing[8]};
`;
