import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './WebTeachingCenterNavigation.styled';
import { TopNavigationLogo } from '../top-navigation-logo';
import { useMemo, useState } from 'react';
import { SubMenuContainer } from '../sub-menu-container';
import { NAVIGATION_MENUS } from '../constants';
import { RootStateOrAny, useSelector } from 'react-redux';
import { User } from 'types';
import { NavigationMenu } from '../constants/menu-list';

interface TeachingCenterNavigationProps {
  menus: NavigationMenu[];
  handleLogout?: () => Promise<void>;
}

export const WebTeachingCenterNavigation = ({ handleLogout }: TeachingCenterNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isHovered, setIsHovered] = useState(false);
  const [hoveredMenu, setHoveredMenu] = useState<(typeof NAVIGATION_MENUS)[number]['label'] | null>(
    null,
  );

  const myInfo = useSelector((state: RootStateOrAny) => state.currentUserReducer).storeInfo as User;

  const handleMenuMouseEnter = (menu: (typeof NAVIGATION_MENUS)[number]['label']) => {
    setIsHovered(true);
    setHoveredMenu(menu);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredMenu(null);
  };

  const menus = useMemo(() => {
    return NAVIGATION_MENUS.filter((menu) => {
      if (menu.isAdmin) {
        return myInfo.admin;
      }
      return true;
    });
  }, [myInfo]);

  return (
    <S.Container onMouseLeave={handleMouseLeave}>
      <S.NavContainer>
        <TopNavigationLogo />
        <S.NavigationMenuContainer>
          {menus.map((menu) => (
            <S.NavigationMenu
              className={location.pathname.includes(menu.href) ? 'selected' : ''}
              key={menu.label}
              onMouseEnter={() => handleMenuMouseEnter(menu.label)}
            >
              {menu.label}
            </S.NavigationMenu>
          ))}
        </S.NavigationMenuContainer>
        <S.NavigationMenu
          onMouseEnter={handleMouseLeave}
          onClick={() => navigate('/teachingcenter/mypage')}
        >
          마이페이지
        </S.NavigationMenu>
      </S.NavContainer>
      <SubMenuContainer isVisible={isHovered} hoveredMenu={hoveredMenu} />
    </S.Container>
  );
};
