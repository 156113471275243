import styled from '@emotion/styled';

export const HrSectText = styled.span`
  display: flex;
  flex-basis: 100%;
  ${({ theme }) => theme.oldTypography.Caption1};
  color: ${({ theme }) => theme.colors.gray3};

  align-items: center;

  &::before {
    content: '';
    flex-grow: 1;
    background: ${({ theme }) => theme.colors.gray4};
    height: 1px;
    margin-right: 16px;
  }
  &::after {
    content: '';
    flex-grow: 1;
    background: ${({ theme }) => theme.colors.gray4};
    height: 1px;
    margin-left: 16px;
  }
`;
