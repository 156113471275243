import styled from '@emotion/styled';

export const SidebarButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: ${({ theme }) => theme.spacing[16]};

  width: 100%;
`;

export const SidebarButtonChildren = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing[4]};
`;
