import { toast, ToastOptions } from 'react-toastify';

interface ToastProps {
  type?: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

const toastConfig: ToastOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  pauseOnFocusLoss: true,
  closeButton: false,
  icon: false,
};

export const showToast = ({ type = 'success', message }: ToastProps) => {
  switch (type) {
    case 'error':
      toast.error(message, toastConfig);
      break;
    case 'warning':
      toast.warning(message, toastConfig);
      break;
    case 'info':
      toast.info(message, toastConfig);
      break;
    case 'success':
    default:
      toast.success(message, toastConfig);
      break;
  }
};
