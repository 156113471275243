import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: ${({ theme }) => theme.spacing[20]};
  padding-right: ${({ theme }) => theme.spacing[20]};
  padding-top: ${({ theme }) => theme.spacing[12]};
  padding-bottom: ${({ theme }) => theme.spacing[32]};

  width: 284px;
  height: calc(100vh - 60px);
  background-color: ${({ theme }) => theme.colors.white};

  overflow-y: auto;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: ${({ theme }) => theme.spacing[28]};
`;

export const Title = styled.h2`
  margin: 0;
  ${({ theme }) => theme.typography['title-regular']};
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
`;

export const Description = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography['body-medium']};
  color: ${({ theme }) => theme.colors.gray4};
  text-align: left;
`;
