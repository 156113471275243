import { useEffect, useState } from 'react';
import { NAVIGATION_MENUS } from '../constants';
import * as S from './SubMenuContainer.styled';
import { useNavigate } from 'react-router-dom';

interface SubMenuContainerProps {
  isVisible: boolean;
  hoveredMenu: (typeof NAVIGATION_MENUS)[number]['label'] | null;
}

export const SubMenuContainer = ({ isVisible, hoveredMenu }: SubMenuContainerProps) => {
  const navigate = useNavigate();

  const [nowHoveredMenu, setNowHoveredMenu] = useState<
    (typeof NAVIGATION_MENUS)[number]['label'] | null
  >(null);

  const [isFading, setIsFading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const menu = NAVIGATION_MENUS.find((menu) => menu.label === nowHoveredMenu);

    if (menu) {
      setTitle(menu.label);
      setDescription(menu.description);
    }
  }, [nowHoveredMenu]);

  useEffect(() => {
    setIsFading(false);
    setTimeout(() => {
      setNowHoveredMenu(hoveredMenu);
      setIsFading(true);
    }, 100);
  }, [hoveredMenu]);

  return (
    <S.Container className={isVisible ? 'visible' : ''}>
      <S.ContentContainer isFading={isFading}>
        <S.TitleContainer>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.TitleContainer>
        <S.SubMenuContainer>
          {NAVIGATION_MENUS.find((menu) => menu.label === nowHoveredMenu)?.subMenus.map(
            (subMenu) => (
              <S.SubMenuCategory key={subMenu.label}>
                <S.SubMenuCategoryTitle>{subMenu.label}</S.SubMenuCategoryTitle>
                {subMenu.menus.map((menu) => (
                  <S.SubMenu
                    key={menu.label}
                    onClick={() => navigate(menu.href)}
                    className={menu.href === window.location.pathname ? 'selected' : ''}
                  >
                    {menu.label}
                  </S.SubMenu>
                ))}
              </S.SubMenuCategory>
            ),
          )}
        </S.SubMenuContainer>
      </S.ContentContainer>
    </S.Container>
  );
};
