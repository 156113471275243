import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const usePortal = (id: string, parentRef?: React.RefObject<HTMLDivElement>) => {
  const rootRef = useRef<Element>();

  useEffect(() => {
    const createRootElement = (id: string) => {
      if (parentRef) {
        const rootContainer = document.createElement('div');
        rootContainer.setAttribute('id', id);
        parentRef.current?.appendChild(rootContainer);
        return rootContainer;
      } else {
        const rootContainer = document.createElement('div');
        rootContainer.setAttribute('id', id);
        document.body.appendChild(rootContainer);
        return rootContainer;
      }
    };

    const parentElement = createRootElement(id);
    rootRef.current = parentElement;

    return () => {
      rootRef.current?.remove();
      if (!parentElement.childElementCount) {
        parentElement.remove();
      }
    };
  }, [id, parentRef]);

  const Portal = useCallback(
    ({ children }: { children: React.ReactNode }) => {
      if (!rootRef.current) return null;
      return createPortal(children, rootRef.current);
    },
    [rootRef],
  );

  return Portal;
};

export default usePortal;
