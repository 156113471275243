import styled from '@emotion/styled';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[16]};

  background-color: ${({ theme }) => theme.colors.black};

  color: ${({ theme }) => theme.colors.white};

  border-radius: ${({ theme }) => theme.borderRadius[16]};

  cursor: pointer;

  ${({ theme }) => theme.typography['body-medium']}

  &:focus {
    background-color: ${({ theme }) => theme.colors.gray1};
    outline: 2px solid ${({ theme }) => theme.colors['blue-100']};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray1};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray5};
    color: ${({ theme }) => theme.colors.white};
    cursor: not-allowed;
  }
`;
