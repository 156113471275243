import styled from '@emotion/styled';

export const Label = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  & > input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    margin: -1px;
    clip-path: inset(50%);
  }
`;

export const Checkbox = styled.span<{ checked: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  border: 2px solid
    ${({ theme, checked, disabled }) => {
      if (disabled) {
        return theme.colors.gray5;
      }
      if (checked) {
        return theme.colors.black;
      }
      return theme.colors.gray4;
    }};

  background-color: ${({ theme }) => theme.colors.white};

  & > svg {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray4 : theme.colors.black)};
  }
`;

export const CheckboxText = styled.span<{ disabled?: boolean }>`
  margin-left: ${({ theme }) => theme.spacing[8]};
  ${({ theme }) => theme.typography['body-large']};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray4 : theme.colors.black)};
`;
