import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faBookOpen, faCircleQuestion, faClock } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

export default function ShortCutCard() {
  const navigate = useNavigate();
  return (
    <Container>
      <ShortCutContainer>
        <ShortCutIcon onClick={() => navigate('/learningcenter/wordgame/main')}>
          <span>
            <FontAwesomeIcon style={{ color: '#4b82fa' }} icon={faCircleQuestion} size="2x" />
          </span>
          <span>
            <p>Quiz</p>
          </span>
        </ShortCutIcon>
        <ShortCutIcon onClick={() => navigate('/learningcenter/timeword/main')}>
          <span>
            <FontAwesomeIcon style={{ color: '#4b82fa' }} icon={faClock} size="2x" />
          </span>
          <span>
            <p>Time Attack</p>
          </span>
        </ShortCutIcon>
        <ShortCutIcon onClick={() => navigate('/learningcenter/voca')}>
          <span>
            <FontAwesomeIcon style={{ color: '#4b82fa' }} icon={faA} size="2x" />
          </span>
          <span>
            <p>Voca</p>
          </span>
        </ShortCutIcon>
        <ShortCutIcon onClick={() => navigate('/learningcenter/reading')}>
          <span>
            <FontAwesomeIcon style={{ color: '#4b82fa' }} icon={faBookOpen} size="2x" />
          </span>
          <span>
            <p>Reading</p>
          </span>
        </ShortCutIcon>
      </ShortCutContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ShortCutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
`;

const ShortCutIcon = styled.div`
  flex: 1;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  padding: 12px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  p {
    margin: 0;
    margin-top: 5px;
    ${({ theme }) => theme.typography['subtitle-small']};
  }
`;
