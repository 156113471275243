import styled from '@emotion/styled';
import { Button } from 'components/atoms';
import BasicLayout from 'components/templates/basic-layout';
import { useNavigate } from 'react-router-dom';

export default function Page404() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <BasicLayout>
      <Styles.Container>
        <Styles.ContentContainer>
          <Styles.Title>잘못된 경로로 접근하셨습니다.</Styles.Title>
          <Button onClick={handleClick}>메인으로 돌아가기</Button>
        </Styles.ContentContainer>
      </Styles.Container>
    </BasicLayout>
  );
}

const Styles = {
  Container: styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    * {
      user-select: none;
    }
  `,
  ContentContainer: styled.div`
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    gap: 24px;
  `,
  Title: styled.span`
    ${({ theme }) => theme.oldTypography.H2}
    text-align: center;

    word-break: keep-all;
  `,
};
