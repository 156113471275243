import db from '../../firebase';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { GrowingSchedule, ScheduleConfig } from 'types';

export async function getScheduleConfig() {
  const querySnapshot = await getDoc(doc(db, 'Schedule', 'config'));

  const config = querySnapshot.data() as ScheduleConfig;

  return config;
}

export async function updateScheduleConfig(config: ScheduleConfig) {
  const scheduleConfigDoc = doc(db, 'Schedule', 'config');
  await setDoc(scheduleConfigDoc, config, { merge: true });
}

export async function getSchedule() {
  const scheduleCollection = query(
    collection(db, 'Schedule', '만덕', 'List'),
    where('subject', '==', 'Competition'),
  );

  const querySnapshot = await getDocs(scheduleCollection);

  let scheduleArray: GrowingSchedule[] = [];

  querySnapshot.forEach((doc) => {
    const scheduleData = doc.data() as GrowingSchedule;
    scheduleArray.push({ ...scheduleData, id: doc.id });
  });

  return scheduleArray;
}

export async function postSchedule(schedule: GrowingSchedule) {
  const { id, ...data } = schedule;
  const scheduleDoc = doc(db, 'Schedule', '만덕', 'List', id);
  await setDoc(scheduleDoc, data);
}

export async function putSchedule(schedule: GrowingSchedule) {
  const { id, ...data } = schedule;
  const scheduleDoc = doc(db, 'Schedule', '만덕', 'List', id);
  await setDoc(scheduleDoc, data, { merge: true });
}

export async function deleteSchedule(id: string) {
  const scheduleDoc = doc(db, 'Schedule', '만덕', 'List', id);
  await deleteDoc(scheduleDoc);
}
