import { Colors } from './colors';
import { ELEVATION } from './elevation';
import { OLD_TYPOGRAPHY } from './old-typography';
import { SPACING } from './spacing';
import { BORDER_RADIUS } from './border-radius';
import { TYPOGRAPHY } from './typography';
import { BREAKPOINTS } from './breakpoints';

export const theme = {
  colors: Colors,
  elevation: ELEVATION,
  oldTypography: OLD_TYPOGRAPHY,
  typography: TYPOGRAPHY,
  spacing: SPACING,
  borderRadius: BORDER_RADIUS,
  breakpoints: BREAKPOINTS,
};

type ThemeInterface = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends ThemeInterface {}
}
