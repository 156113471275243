import styled from '@emotion/styled';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 60px;

  z-index: 3;
`;

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding-right: ${({ theme }) => theme.spacing[60]};
  background-color: ${({ theme }) => theme.colors.white};
  gap: ${({ theme }) => theme.spacing[60]};

  z-index: 2;
`;

export const NavigationMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  width: 100%;
  max-width: 800px;
`;

export const NavigationMenu = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  ${({ theme }) => theme.typography['subtitle-small']};
  color: ${({ theme }) => theme.colors.gray3};
  text-decoration: none;

  cursor: pointer;

  &.selected {
    ${({ theme }) => theme.typography['title-small']};
    color: ${({ theme }) => theme.colors['blue-100']};
  }

  &:hover {
    color: ${({ theme }) => theme.colors['blue-100']};
  }
`;
