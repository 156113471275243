import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(40%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0 24px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;

  height: 100%;

  margin: 112px 0 80px 0;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 556px;

    margin: 112px 0;

    @media (max-height: 631px) {
      margin: 17vh 0;
    }
  }
`;

export const TitleContainer = styled.div`
  width: 100%;

  display: flex;
  gap: 24px;

  align-items: center;

  animation: ${fadeIn} 1s;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    gap: 16px;
  }
`;

export const Title = styled.span`
  ${({ theme }) => theme.oldTypography.HeadLine}

  color: ${({ theme }) => theme.colors.black};

  transition: 0.2s ease all;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ theme }) => theme.oldTypography.H1};
  }
`;

export const SubTitleContainer = styled.div`
  width: 100%;

  margin-top: 78px;

  text-align: left;

  animation: ${fadeIn} 1s;
  animation-delay: 0.2s;
  animation-fill-mode: backwards;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex: 1;

    margin-top: 40px;
  }
`;

export const SubTitle = styled.span`
  ${({ theme }) => theme.oldTypography.Body1};

  color: ${({ theme }) => theme.colors.black};

  white-space: pre-wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ theme }) => theme.oldTypography.Body1};
  }
`;

export const ButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  width: 100%;
  margin-top: 212px;

  gap: 12px;

  animation: ${fadeIn} 1s;
  animation-delay: 0.4s;
  animation-fill-mode: backwards;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: 0;
  }
`;

export const Logo = styled.img`
  width: 70px;
  height: 70px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 40px;
    height: 40px;
  }
`;
