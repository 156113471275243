import * as S from './TopNavigationLogo.styled';
import logo from '../../../../../assets/images/growing_symbol_white.png';
import { useNavigate } from 'react-router-dom';

export const TopNavigationLogo = () => {
  const navigate = useNavigate();

  return (
    <S.Container onClick={() => navigate('/teachingcenter/home')}>
      <S.Logo src={logo} />
      <S.Text>Teaching Center</S.Text>
    </S.Container>
  );
};
