import { useState } from 'react';
import { deleteWordsByCategoryId } from '.';

export const useDeleteWordsByCategoryId = () => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteWords = async (categoryId: string) => {
    setIsLoading(true);
    try {
      await deleteWordsByCategoryId(categoryId);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutate: deleteWords };
};
