import { useRef } from 'react';
import * as S from './Dashboard.styled';
import { TeachingCenterNavigation } from 'components/blocks';
import { OffcanvasProvider } from 'components/molecules';

export const DashboardLayout = ({
  children,
  ...rest
}: { children: React.ReactNode } & React.ComponentPropsWithoutRef<'div'>) => {
  const offcanvasRootRef = useRef<HTMLDivElement>(null);

  return (
    <S.Container ref={offcanvasRootRef} {...rest}>
      <TeachingCenterNavigation />
      <OffcanvasProvider id="offcanvas-root" parentRef={offcanvasRootRef} />
      <S.ContentContainer>{children}</S.ContentContainer>
    </S.Container>
  );
};
