import { useEffect, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import useRedirect from '../../../../../hooks/useRedirect';
import BasicLayout from 'components/templates/basic-layout';
import * as S from 'components/templates/common-container/CommonContainer.styled';
import { TeachingCenterTextInput } from 'components/blocks/forms';
import useGetConfig from 'api/config/useGetConfig';
import useLoadingIndicator from 'hooks/useLoadingIndicator';
import { usePutConfig } from 'api/config/usePutConfig';
import { Button } from 'components/atoms';
import { showToast } from 'components/blocks';

export const ConfigSettingPage = () => {
  const [competitionExtra, setCompetitionExtra] = useState(0);
  const [competitionProportion, setCompetitionProportion] = useState({
    rank: 0,
    quiz: 0,
  });
  const [quizTime, setQuizTime] = useState(0);
  const [maxScore, setMaxScore] = useState({
    listening: 0,
    grammar: 0,
    reading: 0,
    mock: 0,
  });
  const [studentHomeBanner, setStudentHomeBanner] = useState<{ index: number; text: string }[]>([
    { index: 0, text: '' },
  ]);

  const { data: config, isLoading: isConfigLoading } = useGetConfig();
  const { isLoading: isUpdateLoading, mutate: updateConfig } = usePutConfig();

  const handleCompetitionExtraChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      return;
    }

    setCompetitionExtra(Number(e.target.value));
  };

  const handleCompetitionProportionChange = ({
    e,
    key,
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    key: 'rank' | 'quiz';
  }) => {
    if (isNaN(Number(e.target.value))) {
      return;
    }

    setCompetitionProportion((prev) => ({ ...prev, [key]: Number(e.target.value) }));
  };

  const handleQuizTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) {
      return;
    }

    setQuizTime(Number(e.target.value));
  };

  const handleMaxScoreChange = ({
    e,
    key,
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    key: 'listening' | 'grammar' | 'reading' | 'mock';
  }) => {
    if (isNaN(Number(e.target.value))) {
      return;
    }

    setMaxScore((prev) => ({ ...prev, [key]: Number(e.target.value) }));
  };

  const handleStudentHomeBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStudentHomeBanner([{ index: 0, text: e.target.value }]);
  };

  const updateData = async () => {
    if (!config) {
      return;
    }

    try {
      await updateConfig({
        id: config.id,
        data: {
          competitionExtra,
          competitionProportion,
          quizTime,
          maxScore,
          studentHomeBanner,
        },
      });

      showToast({
        type: 'success',
        message: '설정이 업데이트 되었습니다.',
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: '설정 업데이트에 실패했습니다.',
      });
    }
  };

  useRedirect('teachingcenter', 'home');

  useLoadingIndicator({
    id: 'setting',
    isShow: isConfigLoading || isUpdateLoading,
  });

  useEffect(() => {
    setCompetitionExtra(config?.competitionExtra || 0);
    setCompetitionProportion(config?.competitionProportion || { rank: 0, quiz: 0 });
    setQuizTime(config?.quizTime || 0);
    setMaxScore(config?.maxScore || { listening: 0, grammar: 0, reading: 0, mock: 0 });
    setStudentHomeBanner(config?.studentHomeBanner || [{ index: 0, text: '' }]);
  }, [config]);

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Tab.Container id="left-tabs" defaultActiveKey="competitionExtra">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="competitionExtra">컴퍼티션 가산점</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="competitionProportion">컴퍼티션/퀴즈 비율</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="quizTime">퀴즈 시간</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="maxScore">테스트 최대 점수</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="studentHomeBanner">학생 화면 문구 변경</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="competitionExtra">
                    <TeachingCenterTextInput
                      label="컴퍼티션 가산점"
                      value={competitionExtra}
                      onChange={handleCompetitionExtraChange}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="competitionProportion">
                    <Row>
                      <Col md>
                        <TeachingCenterTextInput
                          label="컴퍼티션 순위"
                          value={competitionProportion.rank}
                          onChange={(e) => handleCompetitionProportionChange({ e, key: 'rank' })}
                        />
                      </Col>
                      <Col md>
                        <TeachingCenterTextInput
                          label="퀴즈 점수"
                          value={competitionProportion.quiz}
                          onChange={(e) => handleCompetitionProportionChange({ e, key: 'quiz' })}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="quizTime">
                    <TeachingCenterTextInput
                      label="퀴즈 시간"
                      value={quizTime}
                      onChange={handleQuizTimeChange}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="maxScore">
                    <Row>
                      <Col md>
                        <TeachingCenterTextInput
                          label="Listening"
                          value={maxScore.listening}
                          onChange={(e) => handleMaxScoreChange({ e, key: 'listening' })}
                        />
                      </Col>
                      <Col md>
                        <TeachingCenterTextInput
                          label="Grammar"
                          value={maxScore.grammar}
                          onChange={(e) => handleMaxScoreChange({ e, key: 'grammar' })}
                        />
                      </Col>
                      <Col md>
                        <TeachingCenterTextInput
                          label="Reading"
                          value={maxScore.reading}
                          onChange={(e) => handleMaxScoreChange({ e, key: 'reading' })}
                        />
                      </Col>
                      <Col md>
                        <TeachingCenterTextInput
                          label="모의고사"
                          value={maxScore.mock}
                          onChange={(e) => handleMaxScoreChange({ e, key: 'mock' })}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="studentHomeBanner">
                    <TeachingCenterTextInput
                      label="학생 화면 문구"
                      value={studentHomeBanner[0].text}
                      onChange={handleStudentHomeBannerChange}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
          <Row className="mt-5">
            <Col md>
              <Button onClick={updateData}>업로드</Button>
            </Col>
          </Row>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};
