import { forwardRef, useEffect, useState } from 'react';
import * as S from './InputText.styled';
import { DeleteButton, ErrorIcon } from 'components/atoms';
import { Colors } from 'styles/theme/colors';

interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  label: string;
  errorText?: string;
  helperText?: string;
  completedText?: string;
  isError?: boolean;
  onDelete?: () => void;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      value,
      label,
      disabled = false,
      isError = false,
      errorText = '',
      helperText = '',
      onDelete,
      defaultValue,
      ...rest
    }: InputTextProps,
    ref,
  ) => {
    const [isActive, setIsActive] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isShowError, setIsShowError] = useState(false);

    const inputRef = ref as React.MutableRefObject<HTMLInputElement>;

    const handleFocus = () => {
      if (disabled) {
        return;
      }
      inputRef?.current.focus();
      setIsFocused(true);
      setIsActive(true);
    };

    const handleBlur = () => {
      setIsActive(false);
      if (inputRef?.current.value) {
        return;
      }
      setIsFocused(false);
    };

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (!onDelete) {
        return;
      }
      onDelete();
    };

    const handleInvalid = (e: React.InvalidEvent<HTMLInputElement>) => {
      e.currentTarget.setCustomValidity('');
    };

    useEffect(() => {
      setTimeout(() => {
        setIsShowError(isError && !isActive);
      }, 200);
    }, [isError, isActive]);

    return (
      <S.Container>
        <S.InputContainer isActive={isActive} isError={isShowError} disabled={disabled}>
          <S.ContentContainer onClick={handleFocus}>
            <S.Input
              value={value}
              disabled={disabled}
              ref={ref}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onInvalid={handleInvalid}
              {...rest}
            />
            <S.Label htmlFor={label} isFocused={isFocused || !!value}>
              {label}
            </S.Label>
          </S.ContentContainer>
          {!isShowError && isActive && !!value && <DeleteButton onMouseDown={handleDelete} />}
          {isShowError && (
            <S.ErrorIconContainer isShowError={isError && !isActive}>
              <ErrorIcon color={Colors.red} />
            </S.ErrorIconContainer>
          )}
        </S.InputContainer>
        {!isShowError && !!helperText && <S.HelperText>{helperText}</S.HelperText>}
        {isShowError && !!errorText && <S.HelperText isError>{errorText}</S.HelperText>}
      </S.Container>
    );
  },
);

export default InputText;
