import { OffcanvasService } from 'components/molecules';
import { ModifyCategoryOffCanvas } from 'pages/teachingcenter/word-category/ui';
import { GrowingSchedule } from 'types';

interface HandleEditCategoryOffCanvasOpenProps {
  category: GrowingSchedule;
  onSuccess: () => void;
}

export const handleModifyCategoryOffcanvasOpen = ({
  category,
  onSuccess,
}: HandleEditCategoryOffCanvasOpenProps) => {
  const handleOffcanvasClose = () => {
    OffcanvasService.close();
  };

  const handleOffcanvasSuccess = () => {
    handleOffcanvasClose();
    onSuccess();
  };

  OffcanvasService.open({
    offcanvas: (
      <ModifyCategoryOffCanvas
        onClose={handleOffcanvasClose}
        category={category}
        onSuccess={handleOffcanvasSuccess}
      />
    ),
  });
};
