import { css } from "@emotion/react";

export const getTypography = ({
  fontSize,
  fontWeight,
  lineHeight,
  textDecoration,
}: {
  fontSize: number;
  fontWeight: number;
  lineHeight: number;
  textDecoration?: string;
}) => {
  return css`
    font-family: "Pretendard";
    font-size: ${fontSize}px;
    font-weight: ${fontWeight};
    line-height: ${lineHeight}px;
    letter-spacing: -2%;
    text-decoration: ${textDecoration};
  `;
};

export const TYPOGRAPHY = {
  "head-large": getTypography({
    fontSize: 70,
    fontWeight: 500,
    lineHeight: 104,
  }),
  "head-medium": getTypography({
    fontSize: 60,
    fontWeight: 700,
    lineHeight: 90,
  }),
  "head-regular": getTypography({
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 60,
  }),
  "head-small": getTypography({
    fontSize: 30,
    fontWeight: 700,
    lineHeight: 50,
  }),
  "title-large": getTypography({
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 37,
  }),
  "title-medium": getTypography({
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 30,
  }),
  "title-regular": getTypography({
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 28,
  }),
  "title-small": getTypography({
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 24,
  }),
  "title-tiny": getTypography({
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 20,
  }),
  "subtitle-large": getTypography({
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 34,
  }),
  "subtitle-medium": getTypography({
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 30,
  }),
  "subtitle-regular": getTypography({
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 26,
  }),
  "subtitle-small": getTypography({
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 22,
  }),
  "subtitle-tiny": getTypography({
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 18,
  }),
  "body-large": getTypography({
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 20,
  }),
  "body-medium": getTypography({
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 18,
  }),
  "body-small": getTypography({
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 16,
  }),
  "caption-regular": getTypography({
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 14,
  }),
  "caption-small": getTypography({
    fontSize: 8,
    fontWeight: 400,
    lineHeight: 12,
  }),
  "caption-tiny": getTypography({
    fontSize: 6,
    fontWeight: 400,
    lineHeight: 8,
  }),
  "btn-tiny": getTypography({
    fontSize: 6,
    fontWeight: 500,
    lineHeight: 7.16,
  }),
  "btn-tiny-ul": getTypography({
    fontSize: 6,
    fontWeight: 500,
    lineHeight: 7.16,
    textDecoration: "underline",
  }),
  "btn-small": getTypography({
    fontSize: 8,
    fontWeight: 500,
    lineHeight: 9.55,
  }),
  "btn-small-ul": getTypography({
    fontSize: 8,
    fontWeight: 700,
    lineHeight: 9.55,
    textDecoration: "underline",
  }),
  "btn-regular": getTypography({
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 11.93,
  }),
  "btn-regular-ul": getTypography({
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 11.93,
    textDecoration: "underline",
  }),
  "btn-medium": getTypography({
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 14.32,
  }),
  "btn-medium-ul": getTypography({
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 14.32,
    textDecoration: "underline",
  }),
  "btn-large": getTypography({
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 16.71,
  }),
  "btn-large-ul": getTypography({
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 16.71,
    textDecoration: "underline",
  }),
  "btn-huge": getTypography({
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 19.09,
  }),
  "btn-huge-ul": getTypography({
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 19.09,
    textDecoration: "underline",
  }),
};
