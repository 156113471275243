import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  flex: 1;

  gap: ${({ theme }) => theme.spacing[28]};
  margin-bottom: ${({ theme }) => theme.spacing[28]};

  & > hr {
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.gray5};
  }
`;
