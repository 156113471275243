import usePortal from 'components/hooks/usePortal';
import useOffcanvas from './useOffcanvas';

export const OffcanvasProvider = ({
  id = 'offcanvas-root',
  parentRef,
}: {
  id?: string;
  parentRef?: React.RefObject<HTMLDivElement>;
}) => {
  const { offcanvas } = useOffcanvas();

  const Portal = usePortal(id, parentRef);

  if (!offcanvas) return null;

  return <Portal>{offcanvas}</Portal>;
};
