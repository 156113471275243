import { GrowingConfig, UpdateConfigRequest } from 'types/config';
import db from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export async function getConfig() {
  const configRef = doc(db, 'Setting', '만덕');
  const configSnap = await getDoc(configRef);

  return { id: configSnap.id, ...configSnap.data() } as GrowingConfig;
}

export async function updateConfig({ id, data }: UpdateConfigRequest) {
  const configDoc = doc(db, 'Setting', id);

  await updateDoc(configDoc, data);
}
