import { GrowingSchedule } from 'types';
import * as S from './CategoryTable.styled';
import { handleModifyCategoryOffcanvasOpen } from '../../model';
import { Icon } from 'components/atoms';

const branchOptions = [
  { label: '전체', value: 'common' },
  { label: '본관', value: 'mandeok-1' },
  { label: '신관', value: 'mandeok-2' },
];

interface CategoryTableProps {
  categories: GrowingSchedule[];
  refetchWordCategory: () => void;
  isDisabled: boolean;
}

export const CategoryTable = ({
  categories,
  refetchWordCategory,
  isDisabled,
}: CategoryTableProps) => {
  const getBranchLabel = (branch: string) => {
    return branchOptions.find((option) => option.value === (branch ?? 'common'))?.label ?? '전체';
  };

  return (
    <S.BodyContainer>
      {categories.map((item, index) => (
        <S.Row className="row" key={item.id} isOdd={index % 2 === 0}>
          <S.Cell flex={1}>{Number(item.id.replace('MDC', ''))}</S.Cell>
          <S.Cell flex={3}>{getBranchLabel(item.branch)}</S.Cell>
          <S.Cell flex={3}>{item.name}</S.Cell>
          <S.Cell flex={3}>{item.minset}</S.Cell>
          <S.Cell flex={3}>{item.maxnum}</S.Cell>
          <S.Cell flex={3}>
            <S.IconButton
              onClick={() => {
                handleModifyCategoryOffcanvasOpen({
                  category: item,
                  onSuccess: refetchWordCategory,
                });
              }}
              disabled={isDisabled}
            >
              <Icon icon="PencilIcon" />
            </S.IconButton>
          </S.Cell>
        </S.Row>
      ))}
    </S.BodyContainer>
  );
};
