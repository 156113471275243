import styled from '@emotion/styled';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 60px;

  z-index: 5;
`;

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding-right: ${({ theme }) => theme.spacing[12]};
  background-color: ${({ theme }) => theme.colors.white};

  z-index: 5;
`;

export const NavigationMenuContainer = styled.div`
  position: fixed;
  top: 60px;

  display: flex;
  flex-direction: column;

  width: 100%;

  height: calc(100vh - 60px);

  padding: ${({ theme }) => theme.spacing[32]};

  gap: ${({ theme }) => theme.spacing[16]};

  border-top: 2px solid ${({ theme }) => theme.colors.gray6};

  background-color: ${({ theme }) => theme.colors.white};

  transition: all 0.3s ease-in-out;

  transform: translateY(-100%);

  &.visible {
    transform: translateY(0);
  }
`;

export const NavigationSubMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};

  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;

export const NavigationLabel = styled.h3`
  margin: 0;
  ${({ theme }) => theme.typography['title-tiny']};
  color: ${({ theme }) => theme.colors.gray3};

  text-align: left;
`;

export const NavigationMenu = styled.span`
  display: flex;

  ${({ theme }) => theme.typography['subtitle-small']};
  color: ${({ theme }) => theme.colors.gray3};
  text-decoration: none;

  cursor: pointer;

  &.selected {
    ${({ theme }) => theme.typography['title-small']};
    color: ${({ theme }) => theme.colors['blue-100']};
  }

  &:hover {
    color: ${({ theme }) => theme.colors['blue-100']};
  }
`;

export const IconButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;
