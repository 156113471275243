import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  isFocused: boolean;
  isError: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 8px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.white};

  min-height: 54px;

  ${({ theme, disabled, isFocused, isError }) => {
    if (disabled) {
      return css`
        background-color: ${theme.colors.gray6};
      `;
    }
    if (isFocused) {
      return css`
        border-color: ${theme.colors['blue-100']};
      `;
    }
    if (isError) {
      return css`
        border-color: ${theme.colors.red};
      `;
    }
  }}
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.gray1};
  ${({ theme }) => theme.oldTypography.Text2}

  border: none;
  outline: none;

  cursor: pointer;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray3};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  left: 16px;
  top: calc(100% + 8px);
  color: ${({ theme }) => theme.colors.red};
  ${({ theme }) => theme.oldTypography.Caption1}

  text-align: left;
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  max-height: 200px;
  padding: 12px 0;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.elevation[2]};
  z-index: 10;
`;

export const OptionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  & > .selected {
    color: ${({ theme }) => theme.colors['blue-100']};
    background-color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const OptionItem = styled.li<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray3};
  ${({ theme }) => theme.oldTypography.Text2}

  ${({ theme, disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${theme.colors.gray6};
    `}

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.white : theme.colors.gray6};
  }
`;

export const Label = styled.label`
  ${({ theme }) => theme.oldTypography.Text1}
  color: ${({ theme }) => theme.colors.gray1};

  text-align: left;
`;
