import usePutWordCategory from 'api/schedule/usePutWordCategory';
import { showToast } from 'components/blocks';
import { GrowingSchedule } from 'types';

interface UseHandleModifyCategoryProps {
  onSuccess: () => void;
}

export const useHandleModifyCategory = ({ onSuccess }: UseHandleModifyCategoryProps) => {
  const { isLoading, mutate: putWordCategory } = usePutWordCategory();

  const handleModifyCategory = async (category: GrowingSchedule) => {
    try {
      await putWordCategory(category);
      onSuccess();
      showToast({
        type: 'success',
        message: '카테고리 수정이 완료되었습니다.',
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: '오류가 발생했습니다. 다시 시도해주세요.',
      });
    }
  };

  return { handleModifyCategory, isLoading };
};
