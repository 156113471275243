import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { distance } from '../../../../lib/utils/calculator';
import LastMonthText from '../../LastMonthText';
import ThisMontText from '../ThisMonthText';
import DropdownSubject from './DropdownSubject';

const CardTest = () => {
  // reducers
  // select option reducer
  const selOption = useSelector((state) => state.selOptionReducer);
  // test score reducer
  const testScore = useSelector((state) => state.statArrayReducer).testArray;
  // select option reducer
  const stat = useSelector((state) => state.statArrayReducer).stat;

  const subject = selOption.subject || 'Listening';

  const cardStyle = (stat) => {
    if (stat.this > stat.last && stat.this !== 0 && stat.last !== 0) {
      return 'card-blueberry';
    } else if (stat.this < stat.last && stat.this !== 0 && stat.last !== 0) {
      return 'card-guaba';
    } else return 'card-mango';
  };

  const statScore = (stat) => {
    if (stat.this === 0) return '기록 없음';
    else if (stat.last === 0) return '신규';
    else return distance(stat.last, stat.this);
  };

  const arrow = (stat) => {
    if (stat.last < stat.this && stat.this !== 0 && stat.last !== 0) {
      return ' ⬆';
    } else if (stat.last > stat.this && stat.this !== 0 && stat.last !== 0) {
      return ' ⬇︎';
    } else if (stat.last !== 0 && stat.this !== 0) return ' -';
  };

  return (
    <>
      {testScore.length !== 0 && selOption.toggleTest && (
        <Col lg>
          <Card className={cardStyle(stat.test[subject])}>
            <Card.Body>
              <Row>
                <Col>
                  <DropdownSubject />
                  <ThisMontText />
                  <h1 className="App-left">{stat.test[subject].this}</h1>
                </Col>
                <Col>
                  <LastMonthText />
                  <h2 className="App-right">
                    {statScore(stat.test[subject])}
                    {arrow(stat.test[subject])}
                  </h2>
                  <Card.Text className="App-right">
                    {stat.test[subject].last} →{stat.test[subject].this}
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

export default CardTest;
