import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;

  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100vw;
  max-width: 1440px;
  height: 100%;
  flex-direction: column;

  padding: 64px 24px 40px 24px;
`;

export const ProfileSection = styled.div`
  display: flex;

  justify-content: space-between;

  gap: 16px;
`;

export const NameSection = styled.div`
  display: flex;
  flex-direction: column;

  text-align: left;

  flex: 1;

  gap: 8px;
`;

export const Label = styled.span`
  ${({ theme }) => theme.oldTypography.Body1};
  color: ${({ theme }) => theme.colors.black};
`;

export const Name = styled.span`
  ${({ theme }) => theme.oldTypography.H1};
  color: ${({ theme }) => theme.colors.black};
`;

export const ToDoNumber = styled.span`
  ${({ theme }) => theme.oldTypography.H1};
  color: ${({ theme }) => theme.colors['blue-100']};
`;

export const ToDoNubmerBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  flex: 1;

  border: 1px solid ${({ theme }) => theme.colors['blue-100']};
  border-radius: 8px;
`;
