import { forwardRef, useEffect, useRef, useState } from 'react';
import * as S from './Dropdown.styled';
import { DeleteButton, ErrorIcon } from 'components/atoms';
import { Colors } from 'styles/theme/colors';

export type DropdownOption = {
  label: string;
  value: string;
};

interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  label: string;
  isError?: boolean;
  errorText?: string;
  helperText?: string;
  emptyText?: string;
  completedText?: string;
  initErrorCheck?: boolean;
  onDelete?: () => void;
  handleChange: (value?: string) => void;
  options?: DropdownOption[];
}

export const Dropdown = forwardRef<HTMLInputElement, DropdownProps>(
  (
    {
      id,
      value,
      label,
      isError = false,
      disabled = false,
      errorText = '',
      helperText = '',
      emptyText = '해당하는 옵션이 없습니다.',
      onDelete,
      handleChange,
      defaultValue,
      placeholder,
      initErrorCheck = false,
      options = undefined,
      ...rest
    }: DropdownProps,
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const filteredOptions = options?.filter((option) => {
      if (search.length === 0) return true;
      return option.label.toLowerCase().includes(search.toLowerCase());
    });

    const hasFilteredOptions = filteredOptions?.length !== 0;

    const isShowError = isError && !isFocused;
    const isShowDeleteButton = !isShowError && search;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const selectedOption = options?.find((f) => f.label === inputValue);
      handleChange(selectedOption?.value || '');
      setSearch(inputValue);
      setOpen(true);
    };

    const handleSelectOption = (selectedValue: string) => {
      handleChange(selectedValue);
      setSearch(options?.find((f) => f.value === selectedValue)?.label || '');
      setOpen(false);
    };

    const handleClearInput = () => {
      handleChange();
      setSearch('');
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    useEffect(() => {
      if (value) {
        setSearch(options?.find((f) => f.value === value)?.label || '');
      }
    }, [value, options]);

    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setOpen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <S.Container ref={dropdownRef}>
        <S.Label htmlFor={id}>{label}</S.Label>
        <S.InputContainer disabled={disabled} isFocused={isFocused} isError={isError}>
          <S.Input
            id={id}
            ref={inputRef}
            placeholder={placeholder}
            value={search}
            onChange={handleInputChange}
            onFocus={() => {
              setOpen(true);
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            aria-invalid={isError}
            aria-describedby={isError ? errorText : undefined}
            disabled={disabled}
          />
          {isShowError && <ErrorIcon color={Colors.red} />}
          {isShowDeleteButton && <DeleteButton onMouseDown={handleClearInput} />}
        </S.InputContainer>
        {isShowError && (
          <S.ErrorMessage id="error-message" role="alert">
            {errorText}
          </S.ErrorMessage>
        )}
        {open && (
          <S.DropdownContent>
            <S.OptionList>
              {hasFilteredOptions ? (
                filteredOptions?.map((option) => {
                  return (
                    <S.OptionItem
                      className={option.value === value ? 'selected' : ''}
                      key={option.value}
                      onClick={() => handleSelectOption(option.value)}
                    >
                      {option.label}
                    </S.OptionItem>
                  );
                })
              ) : (
                <S.OptionItem disabled>{emptyText}</S.OptionItem>
              )}
            </S.OptionList>
          </S.DropdownContent>
        )}
      </S.Container>
    );
  },
);
