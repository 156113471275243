import * as S from './BasicLayout.styled';
import { PageType } from 'types';
import LearningCenterNavigation from 'components/blocks/top-navigation/leaning-center-navigation/LeaningCenterNavigation';
import { TeachingCenterNavigation, TextHeader } from 'components/blocks';
import { useRef, useState } from 'react';
import { logout } from 'api/authApi';
import { useNavigate } from 'react-router-dom';
import useLoadingIndicator from 'hooks/useLoadingIndicator';
import useGetMemberInfo from 'api/useGetMemberInfo';
import * as msg from '../../../lib/utils/msg';
import { OffcanvasProvider } from 'components/molecules';

interface BasicLayoutProps {
  children: React.ReactNode;
  headerType?: PageType;
  headerText?: string;
}

export default function BasicLayout({
  children,
  headerType = '',
  headerText = '',
}: BasicLayoutProps) {
  const navigate = useNavigate();
  const offcanvasRootRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: memberInfo } = useGetMemberInfo();
  // 로그아웃 후 로그인 페이지로 이동시키는 함수
  const handleLogout = async () => {
    if (!memberInfo) {
      return;
    }

    setIsLoading(true);

    try {
      await logout();
      // 선생님일 경우 선생님용 로그인 페이지, 선생님이 아닐 경우 학생용 로그인 페이지로 이동
      memberInfo.teacher ? navigate('/teachingcenter') : navigate('/learningcenter');
    } catch {
      // 오류 시 오류 메세지 출력
      alert(msg.errorMsg.default());
    }
    // loading reducer의 main state를 false로 변경
    setIsLoading(false);
  };

  useLoadingIndicator({ id: 'basic-layout-logout', isShow: isLoading });

  return (
    <S.Container ref={offcanvasRootRef} isHeader={!!headerType}>
      <OffcanvasProvider id="offcanvas-root" parentRef={offcanvasRootRef} />
      <Header headerType={headerType} handleLogout={handleLogout} />
      {headerText && <TextHeader headerText={headerText} />}
      {children}
    </S.Container>
  );
}

const Header = ({
  headerType,
  handleLogout,
}: {
  headerType: PageType;
  handleLogout: () => Promise<void>;
}) => {
  if (!headerType) {
    return null;
  }
  if (headerType === 'student') {
    return <LearningCenterNavigation handleLogout={handleLogout} />;
  }
  return <TeachingCenterNavigation handleLogout={handleLogout} />;
};
