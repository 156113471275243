import { Dialog } from 'components/molecules';

interface ClearScoreSetPopupProps {
  onClose: () => void;
  onClear: () => void;
}

export const ClearScoreSetPopup = ({ onClose, onClear }: ClearScoreSetPopupProps) => {
  return (
    <Dialog
      title="정말로 삭제하시겠습니까?"
      onClose={onClose}
      buttons={[
        {
          children: '취소',
          variant: 'outlined',
          onClick: onClose,
        },
        {
          children: '삭제하기',
          variant: 'danger-outlined',
          onClick: onClear,
        },
      ]}
    />
  );
};
