import { useState } from 'react';
import { deleteSchedule } from '.';
import { GrowingSchedule } from 'types';

export default function useDeleteWordCategory() {
  const [isLoading, setIsLoading] = useState(false);

  const deleteWordCategory = async (schedule: GrowingSchedule) => {
    setIsLoading(true);

    try {
      await deleteSchedule(schedule.id);
      setIsLoading(false);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutate: deleteWordCategory };
}
