import { useState } from 'react';
import { StudentPreset } from 'types';
import { postStudentPreset } from '.';
import { useSelector, RootStateOrAny } from 'react-redux';
import { showToast } from 'components/blocks';

interface PostStudentPresetsArgs {
  studentPreset: StudentPreset;
}

export const usePostStudentPresets = () => {
  const [isLoading, setIsLoading] = useState(false);

  const userUid = useSelector((state: RootStateOrAny) => state.currentUserReducer).accountInfo
    .uid as string;

  const postStudentPresets = async ({ studentPreset }: PostStudentPresetsArgs) => {
    if (!userUid) {
      return;
    }

    setIsLoading(true);

    try {
      await postStudentPreset(userUid, studentPreset);
    } catch (error) {
      showToast({
        type: 'error',
        message: '학생 정보 저장 실패',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, mutate: postStudentPresets };
};
