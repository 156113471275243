import { useRef, useState } from 'react';
import * as S from './TeachingCenterSelect.styled';
import { Icon } from 'components/atoms';

interface SelectProps extends Omit<React.ComponentPropsWithoutRef<'button'>, 'onChange'> {
  label?: string;
  value: string;
  options?: {
    label: string;
    value: string;
  }[];
  onChange: (value: string) => void;
  helperText?: string;
  readonly?: boolean;
}

export const TeachingCenterSelect = ({
  id,
  label,
  value,
  options,
  onChange,
  helperText = '',
  readonly = false,
  ...props
}: SelectProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isOptionOpen, setIsOptionOpen] = useState(false);

  const handleOptionOpen = () => {
    setIsOptionOpen(true);
  };

  const handleOptionClose = () => {
    setIsOptionOpen(false);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (readonly) {
      buttonRef.current?.blur();
      return;
    }

    if (isOptionOpen) {
      buttonRef.current?.blur();
    } else {
      buttonRef.current?.focus();
    }
  };

  const handleOptionClick = (value: string) => {
    onChange(value);
    handleOptionClose();
  };

  return (
    <S.Container>
      {label && <label id={id}>{label}</label>}
      <S.ContentContainer>
        <S.Select
          id={id}
          ref={buttonRef}
          onFocus={handleOptionOpen}
          onBlur={handleOptionClose}
          onMouseDown={handleButtonClick}
          readonly={readonly}
          {...props}
        >
          {options?.find((option) => option.value === value)?.label || value}
          <Icon icon={isOptionOpen ? 'TriangleUpIcon' : 'TriangleDownIcon'} />
        </S.Select>
        {helperText && <S.HelperText>{helperText}</S.HelperText>}
      </S.ContentContainer>
      {isOptionOpen && (
        <S.OptionContainer>
          {options?.map((option) => (
            <S.Option key={option.value} onMouseDown={() => handleOptionClick(option.value)}>
              {option.label}
            </S.Option>
          ))}
        </S.OptionContainer>
      )}
    </S.Container>
  );
};
