import styled from '@emotion/styled';
import { OffcanvasDirection } from './Offcanvas.types';
import { css, keyframes } from '@emotion/react';

const opacityOpenTransition = keyframes`
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const opacityCloseTransition = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0.3);
  }
  to {
    background-color: transparent;
  }
`;

const leftOpenTransition = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const leftCloseTransition = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const Backdrop = styled.div<{ isClosed: boolean; closeTime: number }>`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 2;

  animation: ${({ isClosed, closeTime: onCloseTime }) =>
    isClosed
      ? css`
          ${opacityCloseTransition} ${onCloseTime}ms ease-in-out forwards
        `
      : css`
          ${opacityOpenTransition} ${onCloseTime}ms ease-in-out forwards
        `};
`;

export const Container = styled.div<{
  direction: OffcanvasDirection;
  isClosed: boolean;
  closeTime: number;
}>`
  position: fixed;
  height: 100%;
  overflow-y: hidden;
  z-index: 3;

  overflow-behavior: none;

  animation: ${({ isClosed, closeTime: onCloseTime }) =>
    isClosed
      ? css`
          ${leftCloseTransition} ${onCloseTime}ms ease-in-out forwards
        `
      : css`
          ${leftOpenTransition} ${onCloseTime}ms ease-in-out forwards
        `};
`;
