import { DialogService } from 'components/molecules';
import { GrowingSchedule } from 'types';
import { DeleteCategoryDialog } from '../../ui/delete-category-dialog';
import useDeleteWordCategory from 'api/schedule/useDeleteWordCategory';
import { useDeleteWordsByCategoryId } from 'api/word/useDeleteWordsByCategoryId';
import useLoadingIndicator from 'hooks/useLoadingIndicator';

interface UseHandleDeleteCategoryProps {
  onSuccess: () => void;
}

export const useHandleDeleteCategory = ({ onSuccess }: UseHandleDeleteCategoryProps) => {
  const { mutate: deleteWordCategory, isLoading: isDeleteLoading } = useDeleteWordCategory();
  const { mutate: deleteWordsByCategoryId, isLoading: isDeleteWordsLoading } =
    useDeleteWordsByCategoryId();

  useLoadingIndicator({
    id: 'modify-delete-category',
    isShow: isDeleteLoading || isDeleteWordsLoading,
  });

  const handleDeleteCategory = (category: GrowingSchedule) => {
    const onDelete = async () => {
      await deleteWordCategory(category);
      await deleteWordsByCategoryId('MDC12');
      onSuccess();
    };

    DialogService.open({
      dialog: (
        <DeleteCategoryDialog
          onDelete={onDelete}
          isLoading={isDeleteLoading || isDeleteWordsLoading}
        />
      ),
    });
  };

  return { handleDeleteCategory, isLoading: isDeleteLoading || isDeleteWordsLoading };
};
