import { OffcanvasService } from 'components/molecules';
import { CreateCategoryOffCanvas } from '../../ui/create-category-off-canvas';

interface HandleCreateCategoryOffcanvasOpenProps {
  onSuccess: () => void;
}

export const handleCreateCategoryOffcanvasOpen = ({
  onSuccess,
}: HandleCreateCategoryOffcanvasOpenProps) => {
  const handleClose = () => {
    OffcanvasService.close();
  };

  const handleSuccess = () => {
    handleClose();
    onSuccess();
  };

  OffcanvasService.open({
    offcanvas: <CreateCategoryOffCanvas onClose={handleClose} onSuccess={handleSuccess} />,
  });
};
